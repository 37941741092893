import { userBlock, userCheck, userGroupIcon, userMultiple } from '@assets/svg'

export const customerAnalyticsData = [
  {
    id: 1,
    icon: userGroupIcon,
    title: 'Total Customers',
    value: '5',
    background: 'red100',
  },
  {
    id: 2,
    icon: userBlock,
    title: 'Inactive customer',
    value: 'Rs 2,345.48',
    background: 'lightCyan',
  },
  {
    id: 3,
    icon: userMultiple,
    title: 'New Customer',
    value: '15',
    background: 'primary50',
  },
  {
    id: 4,
    icon: userCheck,
    title: 'Active Customer',
    value: '25',
    background: 'green200',
  },
]
