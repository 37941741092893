import { Column } from '@components/Grid'
import OperationZoneFareSetup from '@components/OperationZoneFareSetup'
import Typography from '@components/Typography'
import { DEFAULT_PARCEL_PRICE } from '@constants/routeConstant'
import { useNavigate } from 'react-router-dom'
const zones = [
  {
    totalDrivers: 15,
    categories: 'Documents Fragile Gift',
    zoneName: 'Kathmandu',
    redirect: '/',
  },
  {
    totalDrivers: 25,
    categories: 'Documents Fragile Gifts',
    zoneName: 'Pokhara',
  },
]
const ParcelFareSetup = () => {
  const navigate = useNavigate()
  const handleClick = () => {
    navigate(DEFAULT_PARCEL_PRICE)
  }
  return (
    <Column width="100%" gap="lg">
      <Typography fontColor="grey500" fontSize="2x" fontWeight='xlg'>
        Trip Fare Setup
      </Typography>
      <OperationZoneFareSetup zones={zones} handleClick={handleClick} />
    </Column>
  )
}

export default ParcelFareSetup
