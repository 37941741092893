import styled from '@emotion/styled'
import { BreadCrumbStyleProps, ButtonStyleProps } from './interface'
import { Link } from 'react-router-dom'
import theme from '@utils/theme'

export const BreadCrumStyle = styled.div<BreadCrumbStyleProps>`
  display: ${(props) => props.display || 'flex'};

  justify-content: ${(props) => props.justifyContent || 'space-between'};

  padding: ${(props) => props.padding || ''};
  align-items: ${(props) => props.alignItems || 'center'};
  @media (max-width: 420px) {
    flex-direction: column;
    gap: 4px;
    align-items: flex-start;
  }
`
export const TitleTexStyle = styled.h2`
  font-weight: 500;
  margin: 0px;
`
export const LinkStyle = styled(Link)<ButtonStyleProps>`
  cursor: pointer;
  text-decoration: none;
  color: ${(props) => theme.colors[props?.color || 'white500']};
  background-color: ${(props) =>
    props.outline === 'true'
      ? 'transparent'
      : theme.colors[props?.background || 'primary']};
  border-radius: ${(props) => theme.spacing[props?.radius || 'xs']}px;
  border: ${(props) => `1px solid ${theme.colors[props.border || 'primary']}`};
  padding: 7px 20px;
  font-size: ${theme.fontSizes['md']}px;
  display: flex;
  align-items: center;
  column-gap: 7px;
`

export const ButtonTextStyle = styled.span`
  font-weight: 500;
`
