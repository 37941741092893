import React, { useRef, useEffect, useState } from 'react'
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css' // Ensure you import the Mapbox CSS
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css' // Geocoder CSS
import MapboxDraw from '@mapbox/mapbox-gl-draw' // Import Mapbox Draw
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css' // Mapbox Draw CSS
import { Column } from './Grid'

// Define your access token for Mapbox
mapboxgl.accessToken =
  'pk.eyJ1IjoidGFsaGFraHdqYTA2IiwiYSI6ImNsdTd6OHptdTBhczgybGxsbWdmcjN6ZWwifQ.RBnwQKZHLwgUhnIh0LeP_g'

const MapComponent: React.FC = () => {
  const mapContainer = useRef<HTMLDivElement | null>(null)
  const map = useRef<mapboxgl.Map | null>(null)
  const draw = useRef<MapboxDraw | null>(null) // Ref for MapboxDraw instance
  const [lng, setLng] = useState<number>(85.30014) // Longitude for the initial map position
  const [lat, setLat] = useState<number>(27.700769) // Latitude for the initial map position
  const [zoom, setZoom] = useState<number>(13) // Initial zoom level
  useEffect(() => {
    if (map.current) return // Initialize map only once

    // Initialize the map
    map.current = new mapboxgl.Map({
      container: mapContainer.current!, // The container reference
      style: 'mapbox://styles/mapbox/streets-v11', // Map style
      center: [lng, lat], // Initial map center [longitude, latitude]
      zoom: zoom, // Initial zoom level
    })

    // Add the Geocoder (search bar)
    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken, // Mapbox access token
      mapboxgl: mapboxgl, // Ensure the geocoder knows about the mapbox-gl instance
      marker: true, // Optional: add a marker at the searched location
      placeholder: 'Search for a location...', // Placeholder text in the search bar
    })

    // Add geocoder to the map UI
    map.current.addControl(geocoder)

    // Initialize Mapbox Draw and add it to the map
    draw.current = new MapboxDraw({
      displayControlsDefault: false, // Disable default controls
      controls: {
        polygon: true, // Enable drawing polygons
        line_string: true, // Enable drawing lines
        point: true, // Enable point (marker) drawing
        trash: true, // Enable the trash icon to remove shapes
      },
      defaultMode: 'draw_polygon', // Set default mode (optional)
    })

    map.current.addControl(draw.current, 'top-left')

    // Update longitude, latitude, and zoom on map move
    map.current.on('move', () => {
      if (map.current) {
        setLng(parseFloat(map.current.getCenter().lng.toFixed(4)))
        setLat(parseFloat(map.current.getCenter().lat.toFixed(4)))
        setZoom(parseFloat(map.current.getZoom().toFixed(2)))
      }
    })

    // Handle when a shape (feature) is created or updated
    map.current.on('draw.create' as any, updateArea)
    map.current.on('draw.update' as any, updateArea)

    // Clean up on unmount
    return () => {
      if (map.current) {
        // Ensure map is initialized before calling remove
        if (draw.current) {
          map.current.removeControl(draw.current) // Safely remove the draw control
        }
        map.current.remove() // Safely remove the map
      }
    }
  }, [])

  // Function to update or handle drawn features (e.g., get polygon coordinates)
  const updateArea = () => {
    const data = draw.current?.getAll()
    if (data && data.features.length > 0) {
      const feature = data.features[0]
      const area = JSON.stringify(feature.geometry)
      console.log('Drawn feature:', area)
    }
  }

  return (
    <Column width="100%">
      <div ref={mapContainer} style={{ width: '100%', height: '300px' }} />
    </Column>
  )
}

export default MapComponent
