import { arrowDown } from '@assets/svg'
import { Column, Row } from '@components/Grid'
import SvgIcon from '@components/SvgIcon'
import styled from '@emotion/styled'
import theme from '@utils/theme'
import { useEffect } from 'react'
import { useRef } from 'react'
import { CSSProperties } from 'react'

const DropDown = ({
  expanded,
  setExpanded,
  childrenComponent,
  headerComponent,
  icon,
  headerStyle,
  dropDownStyle,
  animationSpeed = 800,
  closeOnOutsideClick = false,
  disabled = false,
}: {
  expanded: boolean
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>
  childrenComponent: JSX.Element
  headerComponent: JSX.Element
  icon?: JSX.Element
  headerStyle?: CSSProperties
  dropDownStyle?: CSSProperties
  animationSpeed?: number
  closeOnOutsideClick?: boolean
  disabled?: boolean
}) => {
  const refForCountry = useRef<HTMLDivElement>(null)

  const handleClickOutside = () => {
    if (refForCountry.current) {
      setExpanded(false)
    } else {
      setExpanded(true)
    }
  }

  useEffect(() => {
    if (typeof document !== 'undefined' && closeOnOutsideClick) {
      document.addEventListener('click', handleClickOutside)

      return () => {
        document.removeEventListener('click', handleClickOutside)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded])

  return (
    <div style={{ position: 'relative' }}>
      <StyledRow
        justify="space-between"
        align="center"
        onClick={() => !disabled && setExpanded((prev) => !prev)}
        style={headerStyle}
      >
        {headerComponent}
        {icon ? (
          icon
        ) : (
          <ArrowUp
            src={arrowDown}
            expanded={expanded ? 1 : 0}
            speed={animationSpeed}
          />
        )}
      </StyledRow>

      <DropDownWrapper
        expanded={expanded ? 1 : 0}
        style={dropDownStyle}
        ref={refForCountry}
      >
        <DropDownComponent expanded={expanded ? 1 : 0} speed={animationSpeed}>
          {childrenComponent}
        </DropDownComponent>
      </DropDownWrapper>
    </div>
  )
}

export default DropDown

const ArrowUp = styled(SvgIcon)<{
  expanded: 0 | 1
  speed?: number
}>`
  transform: ${(props) => (props.expanded ? 'rotate(0deg)' : 'rotate(180deg)')};
  transition: transform
    ${(props) => (props.speed ? `${props.speed}ms` : '800ms')} ease-in-out;
`

const StyledRow = styled(Row)<{ style: CSSProperties | undefined }>`
  border: 1px solid ${theme.colors.gray50};
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  background-color: ${theme.colors.white200};
  ${(props) => `${props.style}`};
`

const DropDownWrapper = styled.div<{ expanded: 0 | 1 }>`
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 10;
  background: transparent;
  width: 100%;
`

const DropDownComponent = styled(Column)<{ expanded: 0 | 1; speed?: number }>`
  overflow: hidden;
  max-height: ${(props) => (props.expanded ? '500px' : '0')};
  transition:
    max-height ${(props) => (props.speed ? `${props.speed}ms` : '600ms')}
      ease-in-out,
    transform 00ms ease-in-out;
  transform: ${(props) => (props.expanded ? 'translateY(0)' : 'translateY(0)')};
`
