import styled from '@emotion/styled'
import { baseStyles, IStyledBase } from '@interfaces/utils.interface'

const BaseStyledComponent = styled.div<
  IStyledBase & { flexDirection: 'row' | 'column' }
>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection};
  ${baseStyles}
`

const Row = ({ children, ...restProps }: IStyledBase) => {
  return (
    <BaseStyledComponent {...restProps} flexDirection="row">
      {children}
    </BaseStyledComponent>
  )
}

const Column = ({ children, ...restProps }: IStyledBase) => {
  return (
    <BaseStyledComponent {...restProps} flexDirection="column">
      {children}
    </BaseStyledComponent>
  )
}

export { Row, Column }
