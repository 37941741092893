import { fileDownload } from '@assets/svg'
import { Column, Row } from '@components/Grid'
import HighlightedLastWord from '@components/HighlightedLastWord'
import SvgIcon from '@components/SvgIcon'
import Typography from '@components/Typography'
import styled from '@emotion/styled'
import theme from '@utils/theme'

const tripData = {
  tripId: '#1234567',
  date: '09/25/2024',
  totalFare: 250,
  orderStatus: 'Completed',
  paymentStatus: 'Paid',
  tripType: 'Ride Request',
  pricing: {
    tripFare: 250,
    discount: 25,
    irideCommission: 20,
    total: 205,
  },
}
const TripSummaryCard = () => {
  return (
    <Column width="100%" background="white" padding="lg" radius="sm" gap="lg">
      <Row width="100%" justify="space-between" align="center">
        <TripDetails
          tripNumber={tripData.tripId}
          tripDate={tripData.date}
          totalFare={tripData.totalFare}
        />
        <Status
          orderStatus={tripData.orderStatus}
          paymentStatus={tripData.paymentStatus}
          tripType={tripData.tripType}
        />
      </Row>
      <Summary
        tripFare={tripData.pricing.tripFare}
        discount={tripData.pricing.discount}
        irideComission={tripData.pricing.discount}
        total={tripData.pricing.total}
      />
      <Row justify="right" width="100%">
        <ActionButtons />
      </Row>
    </Column>
  )
}

export default TripSummaryCard

interface ITripDetails {
  tripNumber: string
  tripDate: string
  totalFare: string | number
}
const TripDetails = ({ tripNumber, tripDate, totalFare }: ITripDetails) => {
  return (
    <Row align="center" gap="lg">
      <Row
        background="lightBlue"
        padding="sm"
        width="56px"
        height="64px"
        radius="md"
      >
        {/* <Row height="20px" width="20px" border="grey50"></Row> */}
      </Row>
      <Column gap="sm">
        <Typography>{tripNumber}</Typography>
        <Typography>{tripDate}</Typography>
        <HighlightedLastWord text={`Total Fare : ${totalFare}`} />
      </Column>
    </Row>
  )
}

interface IStatus {
  orderStatus: string
  paymentStatus: string
  tripType: string | number
}

const Status = ({ orderStatus, paymentStatus, tripType }: IStatus) => {
  return (
    <Column gap="sm">
      <HighlightedLastWord
        text={`Order Status : ${orderStatus}`}
        align="right"
        highlightColor="green500"
      />
      <HighlightedLastWord
        text={`Payment Status : ${paymentStatus}`}
        align="right"
        highlightColor="green500"
      />

      <Typography align="right"> Trip Ride: {tripType}</Typography>
    </Column>
  )
}

interface ISummary {
  tripFare: string | number
  discount: string | number
  irideComission: string | number
  total: string | number
}

const Summary = ({ tripFare, discount, irideComission, total }: ISummary) => {
  return (
    <Column width="100%" gap="md">
      <TripSummaryMain width="100%">
        <Typography fontColor="grey400">Trip Summary</Typography>
        <Typography align="right" fontColor="grey400">
          Pricing
        </Typography>
      </TripSummaryMain>
      <Row justify="space-between" width="100%" align="center">
        <Column gap="md" align="center">
          <Typography fontColor="grey400">Trip Fare</Typography>
          <Typography fontColor="grey400"> Discount</Typography>
          <Typography fontColor="grey400">Iride Commission</Typography>
          <Typography>Total</Typography>
        </Column>
        <Column gap="md" align="center">
          <Typography align="right">Rs: {tripFare}</Typography>
          <Typography align="right">Rs: {discount}</Typography>
          <Typography align="right">Rs: {irideComission}</Typography>
          <Typography fontColor="green500" align="right" fontSize="lg">
            Rs: {total}
          </Typography>
        </Column>
      </Row>
    </Column>
  )
}

const ActionButtons = () => {
  return (
    <Row gap="1x">
      <Row>
        <SvgIcon src={fileDownload} svgColor="primary" size={16} />
        <Typography fontColor="primary">Print</Typography>
      </Row>
      <Row>
        <SvgIcon src={fileDownload} svgColor="primary" size={16} />
        <Typography fontColor="primary">Invoice Download</Typography>
      </Row>
    </Row>
  )
}
const TripSummaryMain = styled(Row)`
  border-bottom: 1px solid ${theme.colors.grey50};
  padding: 12px 0px;
`
