import { produce } from 'immer'
import { SET, UPDATE, REMOVE, RESET } from './types'
import initialState from './initialState'
const reducer = (
  state: any = initialState,
  action: {
    key?: keyof typeof initialState
    data?: any
    type: string
  },
) =>
  produce(state, (draft: any) => {
    switch (action.type) {
      case SET:
        if (action?.key !== undefined) {
          const data = action.data
          if (data?.items) {
            draft[action.key].items = data?.items || []
            draft[action.key].totalItems = data?.totalItems
            draft[action.key].totalPages = data?.totalPages
            draft[action.key].page = data?.page
          } else {
            draft[action.key] = data
          }
        }
        break
      case RESET:
        draft[action.key] = initialState[action.key]
        break
      case UPDATE:
        break
      case REMOVE:
        break
      default:
        break
    }
  })

export default reducer
