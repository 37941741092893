import { HashRouter as BrowserRouter, Routes, Route } from 'react-router-dom'
import PrivateLayout from './components/layouts/PrivateLayout'
import PublicLayout from './components/layouts/PublicLayout'
import { privateRoutes, publicRoutes } from './routes'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PublicLayout />}>
          {publicRoutes?.map((item, i) =>
            i === 0 ? (
              <Route index {...item} key={i} />
            ) : (
              <Route {...item} key={i} />
            ),
          )}
        </Route>

        <Route path="/" element={<PrivateLayout />}>
          {privateRoutes?.map((item, i) => {
            return i === 0 ? (
              <Route index {...item} key={i} />
            ) : (
              <Route {...item} key={i} />
            )
          })}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
