import { Column, Row } from '@components/Grid'
import Button from '@components/Button'
import Typography from '@components/Typography'
import { Form, Formik } from 'formik'
import FormField from './FormField'

const ZoneFareSetup = ({ zoneName, totalDrivers, isParcel, handleClick }) => {
  const CheckboxElement = [
    {
      label: 'Bike',
      value: 'bike',
    },
    {
      label: 'Car',
      value: 'car',
    },
    {
      label: 'Auto',
      value: 'auto',
    },
  ]
  const parcelCategories = [
    {
      label: 'Documents',
      value: 'documents',
    },
    {
      label: 'Fragile',
      value: 'fragile',
    },
    {
      label: 'Gift',
      value: 'gift',
    },
  ]

  return (
    <Row
      align="center"
      justify="space-between"
      width="100%"
      background="ghostWhite"
      padding="lg"
      radius="sm"
    >
      <Column gap="sm">
        <Typography fontWeight="2x" fontSize="lg">
          {zoneName}
        </Typography>
        <Typography>
          Total Drivers :{' '}
          <Typography variant="span" fontWeight="2x" fontColor="primary">
            {totalDrivers}
          </Typography>
        </Typography>
      </Column>
      <Column gap="sm">
        <Typography fontWeight="2x" fontSize="lg">
          Available {isParcel ? 'parcel' : 'vehicle'} categories in this zone
        </Typography>
        {/* <Typography>{categories}</Typography> */}

        <Formik initialValues={{ vehicle: [] }} onSubmit={() => {}}>
          <Form>
            {!isParcel ? (
              <FormField
                name="vehicle"
                type="checkbox"
                checkboxProps={{
                  checkboxElement: CheckboxElement.map((element) => {
                    return {
                      label: (
                        <Typography key={element.value} fontSize="md">
                          {element.label}
                        </Typography>
                      ),
                      value: element.value,
                    }
                  }),
                }}
              />
            ) : (
              <FormField
                name="parcelCategorires"
                type="checkbox"
                checkboxProps={{
                  checkboxElement: parcelCategories.map((element) => {
                    return {
                      label: (
                        <Typography key={element.value} fontSize="md">
                          {element.label}
                        </Typography>
                      ),
                      value: element.value,
                    }
                  }),
                }}
              />
            )}
          </Form>
        </Formik>
      </Column>
      <Row>
        <Button
          title="View Fare Setup"
          bgColor="primary"
          textColor="white"
          width="11.5rem"
          onClick={handleClick}
        />
      </Row>
    </Row>
  )
}

export default ZoneFareSetup
