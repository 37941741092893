import React from 'react'
import { Column, Row } from '@components/Grid'
import Typography from '@components/Typography'
import SvgIcon from '@components/SvgIcon'
import { carIcon, bikeIcon } from '@assets/svg'
import { ButtonsProps } from '@components/Breadcrum/interface'
import Breadcrum from '@components/Breadcrum'
import Button from '@components/Button'
import styled from '@emotion/styled'

interface Specialist {
  id: number
  Name: string
  trips: string
  earning: string
  doctorName: string
}

interface Specialists {
  id: number
  trips: string
  Name: string
  doctorName: string
}

interface Activity {
  id: number
  trips: string
  ImageName: string
  doctorName: string
}

const specialistsData: Specialist[] = [
  {
    id: 1,
    Name: '1',
    trips: '21',
    doctorName: 'Dr. John Doe',
    earning: '2345',
  },
  {
    id: 2,
    Name: '2',
    trips: '32',
    doctorName: 'Dr. Jane Smith',
    earning: '2345',
  },
  {
    id: 3,
    Name: '3',
    trips: '32',
    doctorName: 'Dr. Emily Davis',
    earning: '2345',
  },
  {
    id: 4,
    Name: '4',
    trips: '23',
    doctorName: 'Dr. Michael Brown',
    earning: '2345',
  },
  {
    id: 5,
    Name: '5',
    trips: '43',
    doctorName: 'Dr. Sarah Wilson',
    earning: '2345',
  },
]

const specialistsDatas: Specialists[] = [
  { id: 1, Name: '1', trips: '11: 00 Pm', doctorName: 'Dr. Biju Doe' },
  { id: 2, Name: '2', trips: '12: 00 Pm', doctorName: 'Dr. Biju Smith' },
  { id: 3, Name: '3', trips: '01: 00 Pm', doctorName: 'Dr. Biju Davis' },
  { id: 4, Name: '4', trips: '02: 00 Pm', doctorName: 'Dr. Biju Brown' },
  { id: 5, Name: '5', trips: '03: 00 Pm', doctorName: 'Dr. Biju Wilson' },
]

const recentTrips: Activity[] = [
  { id: 1, ImageName: carIcon, trips: '11: 00 Pm', doctorName: 'Dr. Biju Doe' },
  {
    id: 2,
    ImageName: bikeIcon,
    trips: '12: 00 Pm',
    doctorName: 'Dr. Biju Smith',
  },
  {
    id: 3,
    ImageName: carIcon,
    trips: '01: 00 Pm',
    doctorName: 'Dr. Biju Davis',
  },
  {
    id: 4,
    ImageName: bikeIcon,
    trips: '02: 00 Pm',
    doctorName: 'Dr. Biju Brown',
  },
]

const buttons: ButtonsProps[] = [
  {
    title: 'Today',
    type: 'button',
    outline: 'true',
    color: 'primary',
    border: 'grey50',
    textColor: 'grey400',
    fontSize: 'sm',
    bgColor: 'white100',

    wrapperPadding: '2px 0px 0px 0px',
  },
  {
    title: 'This Week',
    type: 'button',
    outline: 'true',
    color: 'primary',
    border: 'grey50',
    textColor: 'grey400',
    fontSize: 'sm',
    bgColor: 'white100',
    wrapperPadding: '2px 0px 0px 0px',
  },
  {
    title: 'This Month',
    type: 'button',
    outline: 'true',
    color: 'primary',
    border: 'grey50',
    textColor: 'grey400',
    fontSize: 'sm',
    bgColor: 'white100',
    wrapperPadding: '2px 0px 0px 0px',
  },
  {
    title: 'All Time',
    type: 'button',
    outline: 'true',
    color: 'primary',
    textColor: 'primary',
    fontSize: 'sm',
    bgColor: 'primary50',
    wrapperPadding: '2px 0px 0px 0px',
  },
]

export const DashboardTable = () => {
  return (
    <>
      <Wrapper gap="lg">
        {/* First Table */}
        <Column
          background="white"
          radius="sm"
          border="grey50"
          gap="md"
          width="100%"
        >
          <Row padding="md">
            <Typography
              fontColor="grey500"
              fontSize="lg"
              fontWeight="xlg"
              style={{ marginRight: '8px' }}
            >
              Top
            </Typography>
            <Typography fontColor="primary" fontSize="lg" fontWeight="xlg">
              Driver
            </Typography>
          </Row>
          <Row
            style={{ display: 'flex', justifyContent: 'space-around' }}
            width="100%"
          >
            <Breadcrum title="" buttons={buttons} />
          </Row>
          <Row
            style={{ display: 'flex', justifyContent: 'space-around' }}
            width="100%"
            background="primary50"
            padding="md"
          >
            <Column>S.N.</Column>
            <Column>Name</Column>
            <Column>No. of Trips</Column>
            <Column>Earnings</Column>
          </Row>
          {specialistsData.map((specialist) => (
            <Row
              key={specialist.id}
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                padding: '0px 0px 8px 0px',
              }}
              width="100%"
            >
              <Column>
                <SvgIcon src={specialist.Name} />
              </Column>
              <Column>
                <Typography fontSize="md">{specialist.doctorName}</Typography>
              </Column>
              <Column padding="xs" border="1px solid break">
                <Typography fontSize="md">{specialist.trips}</Typography>
              </Column>
              <Column padding="xs" border="1px solid break">
                <Typography fontSize="md">{specialist.earning}</Typography>
              </Column>
            </Row>
          ))}
        </Column>
        {/* SECOND PART 1 TABLE */}
        <Column
          background="white"
          radius="sm"
          border="grey50"
          gap="md"
          width="100%"
        >
          <Row padding="md">
            <Typography
              fontColor="grey500"
              fontSize="lg"
              fontWeight="xlg"
              style={{ marginRight: '8px' }}
            >
              Active
            </Typography>
            <Typography fontColor="primary" fontSize="lg" fontWeight="xlg">
              Customer
            </Typography>
          </Row>
          <Row
            style={{ display: 'flex', justifyContent: 'space-around' }}
            width="100%"
          >
            <Breadcrum
              title=""
              // padding="0px 10px 0px 10px"
              buttons={buttons}
            />
          </Row>
          <Row
            style={{ display: 'flex', justifyContent: 'space-around' }}
            width="100%"
            background="primary50"
            padding="md"
          >
            <Column>S.N.</Column>
            <Column>Name</Column>
            <Column>No. of Trips</Column>
          </Row>
          {specialistsDatas.map((specialist) => (
            <Row
              key={specialist.id}
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                padding: '0px 0px 8px 0px',
              }}
              width="100%"
            >
              <Column>
                <SvgIcon src={specialist.Name} />
              </Column>
              <Column>
                <Typography fontSize="md">{specialist.doctorName}</Typography>
              </Column>
              <Column padding="xs" border="1px solid break">
                <Typography fontSize="md">{specialist.trips}</Typography>
              </Column>
            </Row>
          ))}
        </Column>
        {/* Second Table */}
        <Column
          background="white"
          radius="sm"
          border="grey50"
          gap="2x"
          width="100%"
        >
          <Row justify="space-between" width="100%" align="center" padding="md">
            <Column>
              <Typography fontColor="grey500" fontSize="lg" fontWeight="xlg">
                Recent Trips Activity
              </Typography>
            </Column>
            <Column>
              <Typography fontColor="primary" fontSize="lg">
                View All
              </Typography>
            </Column>
          </Row>
          {recentTrips.map((specialist) => (
            <Row
              key={specialist.id}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '0px 10px 18px 10px',
              }}
              width="100%"
            >
              <Row gap="xs">
                <Column>
                  <SvgIcon src={specialist.ImageName} />
                </Column>
                <Column>
                  <Typography fontSize="md">Trip#021</Typography>
                  <Typography fontSize="md">12/03/2024</Typography>
                </Column>
              </Row>
              <Column border="1px solid break">
                <Button
                  title="Completed"
                  type="button"
                  outline="true"
                  textColor="green500"
                  fontSize="sm"
                  bgColor="green50"
                />
              </Column>
            </Row>
          ))}
        </Column>
      </Wrapper>
    </>
  )
}

const Wrapper = styled(Row)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`
