import Button from '@components/Button'
import FormField from '@components/FormField'
import { Column, Row } from '@components/Grid'
import Typography from '@components/Typography'
import styled from '@emotion/styled'
import { Form, Formik } from 'formik'
import DataTable from '@components/DataTable'
import Breadcrum from '@components/Breadcrum'
import SvgIcon from '@components/SvgIcon'
import {
  bikeIcon,
  trashBin,
  carIcon,
  arrowDownload,
  plusColorIcon,
  pencilEdit,
} from '@assets/svg'
import { ButtonsProps } from '@components/Breadcrum/interface'
import ToggleButton from '@components/FormField/ToggleButton'

const DocumentSetup = () => {
  const columns = [
    {
      title: 'S.N.',
      key: 'caption',
      render: (values: any) => {
        const caption = values?.row?.user ?? 'N/A'
        const truncatedName =
          caption?.length > 20 ? caption?.slice(0, 40) + '...' : caption
        return (
          <Row gap="sm" justify="center">
            {truncatedName}
          </Row>
        )
      },
    },

    {
      title: 'Document Name',
      key: 'caption',
      render: (values: any) => {
        const caption = values?.row?.name ?? 'N/A'
        const truncatedName =
          caption?.length > 20 ? caption?.slice(0, 40) + '...' : caption
        return (
          <Row gap="sm" justify="center">
            {truncatedName}
          </Row>
        )
      },
    },
    {
      title: 'Status',
      key: 'tripStatus',
      render: (values: any) => {
        const CustomerName = values?.row?.TripStatus ?? 'N/A'
        let textColor
        let bgColor = 'primary50'

        switch (CustomerName) {
          case 'Pending':
          case 'Cancelled':
            textColor = 'primary'
            break
          case 'Ongoing':
            textColor = 'SkyDark'
            bgColor = 'lightSky'
            break
          case 'Completed':
            textColor = 'green500'
            bgColor = 'green50'
            break
          default:
            textColor = 'primary'
        }

        return (
          <>
            <Row gap="sm" justify="center">
              <ToggleButton
                name="exampleToggle"
                defaultChecked={true}
                onChange={(checked) => console.log('Toggle is now:', checked)}
              />
            </Row>
          </>
        )
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (values: any) => {
        return (
          <Row gap="sm" justify="center">
            <SvgIcon src={pencilEdit} />
            <SvgIcon src={trashBin} />
          </Row>
        )
      },
    },
  ]

  const buttons: ButtonsProps[] = [
    {
      title: 'Download',
      type: 'button',
      outline: 'true',
      border: 'primary',
      color: 'primary',
      frontIcon: <SvgIcon src={arrowDownload} />,
      textColor: 'primary',
      wrapperPadding: '0px 0px 0px 20px',
      bgColor: 'white100',
    },
  ]

  const staticData = [
    {
      name: 'Citizenship',
      user: '1',
      body: 'Get Citizenship',
      promos: 'Promo',
      registerDate: 'August 1 2024',
      totalTrip: carIcon,
      redirectLink: 'www.iride.com',
      status: 'Active',
    },
    {
      name: 'Citizenship',
      user: 'Driving License',
      body: 'Get Citizenship',
      promos: 'Promo',
      registerDate: 'August 1 2024',
      totalTrip: bikeIcon,
      redirectLink: 'www.iride.com',
      status: 'Active',
    },
  ]
  const handleSubmit = async (values, { resetForm }) => {
    console.log(values)
    resetForm()
  }
  const CheckboxElement = [
    {
      label: 'Is Required?',
      value: 'isrequired',
    },
    {
      label: 'Status',
      value: 'status',
    },
  ]
  return (
   <>
    <Column gap="md" width='100%'>
    <Column background="white" padding="lg" radius="sm" gap="lg" width="100%">
      <Typography fontSize="lg" fontWeight="xlg">
        Add Vehicle Model
      </Typography>
      <Formik
          initialValues={{
            vehicle: '',
            image: null,
            vehicleType: '',
            checkboxGroup: [],
          }}
          onSubmit={handleSubmit}
        >
          <FormWrapper>
            <Row gap="md" align="center">
              <FormField
                type="text"
                label="Document Type"
                placeholder="Ex: Citizenship"
                name="title"
              />
              <FormField
                type="text"
                label="Type Order Number"
                placeholder="Ex: 1"
                name="title"
                style={{ width: '40%' }}
              />
              <Button
                title="Add Fields"
                border="primary"
                width="40%"
                radius="sm"
                frontIcon={<SvgIcon src={plusColorIcon} size={18} />}
                textColor="primary"
                type="submit"
                bgColor="white100"
                style={{ marginTop: 'auto' }}
              />
            </Row>
            <Row gap="lg" align="center">
              <FormField
                type="text"
                label="Input Title"
                placeholder="Ex: Citizenship"
                name="title"
              />

              <FormField
                type="select"
                name="inputType"
                placeholder="Select"
                label="Input type"
                select={{
                  titleAsKey: 'label',
                  valueAsKey: 'value',
                }}
                data={[
                  { value: 'text', label: 'Text' },
                  { value: 'number', label: 'Number' },
                  { value: 'date', label: 'Date' },
                  { value: 'image', label: 'Image' },
                ]}
              />
              <FormField
                direction="column-reverse"
                name="select"
                type="checkbox"
                style={{
                  justifyContent: 'space-around',
                }}
                checkboxProps={{
                  checkboxElement: CheckboxElement.map((element) => {
                    return {
                      label: (
                        <Typography key={element.value} fontSize="md">
                          {element.label}
                        </Typography>
                      ),
                      value: element.value,
                    }
                  }),
                }}
              />

              {/* title: 'Download',
      type: 'button',
      outline: 'true',
      border: 'primary',
      color: 'primary',
      frontIcon: <SvgIcon src={arrowDownload} />,
      textColor: "primary",
      wrapperPadding: '0px 0px 0px 20px',
      bgColor: 'white100', */}
            </Row>
            <Row>
              {/* <FormField
              type="text"
              label="Redirect link"
              placeholder="Ex: www.iride.com"
              name="link"
            /> */}
            </Row>

            <Row width="100%" justify="flex-end">
              <Button
                title="Save"
                width="15%"
                radius="sm"
                textColor="white"
                type="submit"
                bgColor="primary"
              />
            </Row>
          </FormWrapper>
        </Formik>
    </Column>
    <Typography fontSize="2x">Vehicle Model List</Typography>
      <DataTable
        data={staticData}
        search
        columns={columns}
        onFilterChange={(filter) => console.log('Filter changed:', filter)}
        breadcrum={<Breadcrum title="" buttons={buttons} />}
      />
    </Column>
    </>
  )
}

export default DocumentSetup

const FormWrapper = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`
