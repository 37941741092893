import React from 'react'
import { Field } from 'formik'
import styled from '@emotion/styled'
import theme from '@utils/theme'

const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 28px;

  input {
    display: none;
  }

  span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    border-radius: 34px;
    transition: background-color 0.4s;
  }

  span:before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    border-radius: 50%;
    transition: transform 0.4s;
  }

  input:checked + span {
    background-color: ${theme.colors.green500};
  }

  input:checked + span:before {
    transform: translateX(26px);
  }
`
interface SwitchProps {
  name: string
}
const Switchs: React.FC<SwitchProps> = ({ name }) => {
  return (
    <Switch>
      <Field
        type="checkbox"
        name={name}
        render={({ field, form }: any) => (
          <>
            <input
              {...field}
              type="checkbox"
              checked={field.value}
              onChange={(e) => {
                form.setFieldValue(name, e.target.checked)
              }}
            />
            <span />
          </>
        )}
      />
    </Switch>
  )
}

export default Switchs
