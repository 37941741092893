import { Column, Row } from '@components/Grid';
import DataTable from '@components/DataTable';
import Breadcrum from '@components/Breadcrum';
import SvgIcon from '@components/SvgIcon'; 
import Dashcard from '@components/Dashcard';
import { trashBin ,eyeIcon, arrowDownload} from '@assets/svg'
import { customerAnalyticsData } from '@assets/dummyData/customerAnalytics';
import Typography from '@components/Typography';
import Button from '@components/Button';
import {  ButtonsProps } from '@components/Breadcrum/interface';

const PageSetup = () => {
  const columns = [
    {
      title: "Trip ID",
      key: "caption",
      render: (values: any) => {
        const caption = values?.row?.tripId  ?? "N/A";
        const truncatedName = caption?.length > 20 ? caption?.slice(0, 40) + "..." : caption;
        return (
          <>
            <Row gap="sm" justify="center">
            #1234567
            </Row>
            <Row gap="sm" justify="center">
              {truncatedName}
            </Row>
          </>
        );
      },
    },
    {
      title: "Customer",
      key: "categories",
      render: (values: any) => {
        const CustomerName = values?.row?.Customer  ?? "N/A";
        return (
          <Row gap="sm" justify="center">
           {CustomerName}
          </Row>
        );
      },
    },
    {
      title: "Driver",
      key: "links",
      render: (values: any) => {
        const CustomerName = values?.row?.Driver  ?? "N/A";
        return (
          <Row gap="sm" justify="center">
           {CustomerName}
          </Row>
        );
      },
    },
    {
      title: "Trip Fare",
      key: "price",
      render: (values: any) => {
        const CustomerName = values?.row?.TripFare  ?? "N/A";
        return (
          <Row gap="sm" justify="center">
           {CustomerName}
          </Row>
        );
      },
    },
    {
      title: "Discount",
      key: "status",
      render: (values: any) => {
        const CustomerName = values?.row?.Discount  ?? "N/A";
        return (
          <Row gap="sm" justify="center">
           {CustomerName}
          </Row>
        );
      },
    },
    {
      title: "Commission",
      key: "action",
      render: (values: any) => {
        const CustomerName = values?.row?.Commission  ?? "N/A";
        return (
          <Row gap="sm" justify="center">
           {CustomerName}
          </Row>
        );
      },
    },
    {
      title: "Payment Status",
      key: "paymentStatus",
      render: (values: any) => {
        const CustomerName = values?.row?.PaymentStatus  ?? "N/A";
        const textColor = CustomerName === "Paid" ? "green500" : "primary";
        const bgColor = CustomerName === "Paid" ? "green50" : "primary50";
        return (
          <>
            <Button title={CustomerName}
            textColor= {textColor}
            bgColor= {bgColor} 
            />
          </>
        );
      },
    },
    {
      title: "Trip Status",
      key: "tripStatus",
      render: (values: any) => {
        const CustomerName = values?.row?.TripStatus ?? "N/A";
        let textColor;
        let bgColor = 'primary50';  
    
        switch (CustomerName) {
          case "Pending":
          case "Cancelled":
            textColor = "primary"; 
            break;
          case "Ongoing":
            textColor = "SkyDark"; 
            bgColor= "lightSky";
            break;
          case "Completed":
            textColor = "green500"; 
            bgColor= "green50";
            break;
          default:
            textColor = "primary"; 
        }
    
        return (
          <Button
            title={CustomerName}
            type='button'
            outline='true'
            textColor={textColor}  
            bgColor={bgColor}     
          />
        );
      },
    },
    
    {
      title: "Action",
      key: "action",
      render: (values: any) => {
        return (
          <Row>
          <SvgIcon src={eyeIcon}/>
          <SvgIcon src={trashBin}/>
          </Row>
        );
      },
    },
  ];

  const buttons: ButtonsProps[] = [
    {
      title: 'All',
      type: 'button', 
      outline: 'true', 
      color: 'primary',
      textColor: "primary",
      wrapperPadding: '4px 0px 0px 0px',
      bgColor: 'primary50',
    },
    {
      title: 'Pending',
      type: 'button',
      outline: 'true',
      color: 'primary',
      border: 'grey50',
      textColor: "grey400",
      wrapperPadding: '4px 0px 0px 0px',
      bgColor: 'white100',
    },
    {
      title: 'Accepted',
      type: 'button',
      outline: 'true',
      color: 'primary',
      border: 'grey50',
      textColor: "grey400",
      bgColor: 'white100',
      wrapperPadding: '4px 0px 0px 0px',
    },
    {
      title: 'Ongoing',
      type: 'button',
      outline: 'true',
      color: 'primary',
      border: 'grey50',
      textColor: "grey400",
      wrapperPadding: '4px 0px 0px 0px',
      bgColor: 'white100',
    },
    {
      title: 'Completed',
      type: 'button',
      outline: 'true',
      color: 'primary',
      border: 'grey50',
      textColor: "grey400",
      wrapperPadding: '4px 0px 0px 0px',
      bgColor: 'white100',
    },
    {
      title: 'Download',
      type: 'button',
      outline: 'true',
      border: 'primary',
      color: 'primary',
      frontIcon: <SvgIcon src={arrowDownload} />,
      textColor: "primary",
      wrapperPadding: '0px 0px 0px 20px',
      bgColor: 'white100',
    },
  ];
  


  const staticData = [
    { "tripId": "TRP123","Customer": "Biju Shrestha", "Driver": "Biju Shrestha","TripFare": 190,"Discount":200,"Commission": 800,"PaymentStatus": "Unpaid", "TripStatus": "Pending", "startDate": "2024-09-28", "endDate": "2024-09-30", "status": "Active", "driverName": "John Doe" },
    { "tripId": "TRP123","Customer": "Biju Shrestha", "Driver": "Biju Shrestha","TripFare": 190,"Discount":200,"Commission": 800,"PaymentStatus": "Unpaid", "TripStatus": "Ongoing", "startDate": "2024-09-28", "endDate": "2024-09-30", "status": "Active", "driverName": "John Doe" },
    { "tripId": "TRP123","Customer": "Biju Shrestha", "Driver": "Biju Shrestha","TripFare": 190,"Discount":200,"Commission": 800,"PaymentStatus": "Unpaid", "TripStatus": "Cancelled", "startDate": "2024-09-28", "endDate": "2024-09-30", "status": "Active", "driverName": "John Doe" },
    { "tripId": "TRP123","Customer": "Biju Shrestha", "Driver": "Biju Shrestha","TripFare": 190,"Discount":200,"Commission": 800,"PaymentStatus": "Paid", "TripStatus": "Completed", "startDate": "2024-09-28", "endDate": "2024-09-30", "status": "Active", "driverName": "John Doe" },
    { "tripId": "TRP123","Customer": "Biju Shrestha", "Driver": "Biju Shrestha","TripFare": 190,"Discount":200,"Commission": 800,"PaymentStatus": "Paid", "TripStatus": "Completed", "startDate": "2024-09-28", "endDate": "2024-09-30", "status": "Active", "driverName": "John Doe" },
    { "tripId": "TRP123","Customer": "Biju Shrestha", "Driver": "Biju Shrestha","TripFare": 190,"Discount":200,"Commission": 800,"PaymentStatus": "Paid", "TripStatus": "Completed", "startDate": "2024-09-28", "endDate": "2024-09-30", "status": "Active", "driverName": "John Doe" },
  ];
  return (
    <Column gap="md" width="100%">
      <Typography fontColor="grey500" fontSize="2x" fontWeight='xlg'>Trip Analytics</Typography>
      <Dashcard data={customerAnalyticsData} />
      <Typography fontColor="grey500" fontSize="2x" fontWeight='xlg'>Trip List</Typography>
      <DataTable
        data={staticData}
        search
        columns={columns}
        onFilterChange={(filter) => console.log('Filter changed:', filter)}
        breadcrum={<Breadcrum title="" buttons={buttons} />}
      />
    </Column>
  )
}

export default PageSetup;
