import { Column, Row } from '@components/Grid';
import { CustomerInfoCard } from '@components/Card/CustomerInfoCard'
import Typography from '@components/Typography'
import { useState } from 'react'
import Button from '@components/Button'
import CustomerList from './customerList';
const CustomerView = () => {
  const [activeButton, setActiveButton] = useState('Trip')
  const buttonConfigs = [
    { title: 'Trips', key: 'type' },
    { title: 'Transaction', key: 'brand' },
    { title: 'Review', key: 'model' },
  ]
  const renderedForm = () => {
    switch (activeButton) {
      case 'Trips':
        return <CustomerList />
      case 'Transaction':
        return <CustomerList />
      case 'Review':
        return <CustomerList />
      default:
        return <CustomerList />
    }
  }
  return (
  <>
  <Column gap="md" width="100%">
    <Row width="100%" gap="md" style={{ alignItems: 'stretch' }}>
      <CustomerInfoCard
        title="Customer Info"
        customerImage="https://images.pexels.com/photos/573238/pexels-photo-573238.jpeg?cs=srgb&dl=pexels-bertellifotografia-573238.jpg&fm=jpg"
        email="driver@gmail.com"
        customerName="Amanada Hart"
        phoneNumber="+977 9890909987"
      />
      <Column 
        background="white"
        padding="lg"
        radius="sm"
        border="grey50"
        width="100%"
        style={{ flexGrow: 1 }} 
        >
        <Typography fontColor="primary" fontSize="lg">Trips Details</Typography>
        <Row justify="space-between" width="100%" style={{padding: "20px 0px 10px 0px"}}>
          <Column>Total Completed Trip</Column>
          <Column>3</Column>
        </Row>
        <Row justify="space-between" width="100%">
          <Column>Total Cancelled Trip</Column>
          <Column>0</Column>
        </Row>
      </Column>
      {/* Part 2 Column */}
      <Column
        background="white"
        padding="lg"
        radius="sm"
        border="grey50"
        width="100%"
        style={{ flexGrow: 1 }} 
        >
        <Typography fontColor="primary" fontSize="lg">Review Info</Typography>
        <Row justify="space-between" width="100%" style={{padding: "20px 0px 10px 0px"}}>
          <Column>Total Review Given</Column>
          <Column>3</Column>
        </Row>
        <Row justify="space-between" width="100%">
          <Column>Total Review Received</Column>
          <Column>1</Column>
        </Row>
      </Column>
    </Row>
    <Row width="100%" gap="sm">
    <Column gap="sm" width="100%">
      <Row width="100%" gap="sm">
        {buttonConfigs.map((button) => (
          <Button
            key={button.key}
            title={button.title}
            bgColor={activeButton === button.title ? 'primary50' : 'white'}
            textColor={activeButton === button.title ? 'primary' : 'grey400'}
            radius="sm"
            width="fit-content"
            onClick={() => setActiveButton(button.title)}
          />
        ))}
      </Row>
      <Row style={{padding: "10px 0px 0px 0px"}} width="100%">{renderedForm()}</Row>
    </Column>
    </Row>
  </Column>
    
  </>
  
  
  )
}

export default CustomerView
