import React from 'react'
import ZoneFareSetup from './ZoneFareSetup' // Assuming you have a separate ZoneFareSetup component
import { Column } from './Grid'
import Typography from './Typography'

interface IOperationZoneFareSetup {
  isParcel?: boolean
  zones: any
  handleClick: () => void
}
const OperationZoneFareSetup = ({
  isParcel = true,
  zones,
  handleClick,
}: IOperationZoneFareSetup) => {
  return (
    <Column background="white" width="100%" radius="sm" gap="2x" padding="1x">
      <Typography fontWeight="2x" fontSize="lg" fontColor="primary">
        Operation Zone Wise {isParcel ? 'Parcel' : 'Ride'} Fare Setup
      </Typography>
      <Column background="white" width="100%" radius="sm" gap="2x">
        {zones.map((zone, index) => (
          <ZoneFareSetup
            handleClick={handleClick}
            isParcel={isParcel}
            key={index}
            totalDrivers={zone.totalDrivers}
            categories={zone.categories}
            zoneName={zone.zoneName}
          />
        ))}
      </Column>
    </Column>
  )
}

export default OperationZoneFareSetup
