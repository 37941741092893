import { Row } from '@components/Grid'
import Typography from '@components/Typography'
import styled from '@emotion/styled'
import theme from '@utils/theme'
import { CSSProperties } from 'react'

interface CheckboxProps {
  checked: boolean
  onChange: () => void
  radiusFull?: boolean
}

const CheckboxWrapper = styled.div`
  display: inline-block;
  vertical-align: middle;

  //   display: flex;
`

// const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
//   position: absolute;
//   opacity: 0;
//   cursor: pointer;
// `

const HiddenCheckbox = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`

const StyledCheckbox = styled.div<{
  checked?: boolean
  isFocused?: boolean
  radiusFull?: boolean
}>`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${(props) =>
    props.checked ? theme.colors.primary : `${theme.colors.grey50}`};
  border: 1px solid ${theme.colors.gray400};
  border-radius: ${(props) => (props.radiusFull ? '50%' : '8px')};
  border: none;
  transition: all 150ms;
  cursor: pointer;

  ${(props) => props.isFocused && `box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);`}

  svg {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
    fill: ${theme.colors.white};
    /* position: absolute; */
    transform: translate(30%, 0%);
  }
`

export interface ICheckboxElement {
  label: JSX.Element
  value: string
}

const Checkbox = ({
  checkboxElement,
  form,
  name,
  checkboxDirection = 'row',
  radiusFull = true,
  mainStyle,
  style,
}: {
  checkboxElement: ICheckboxElement[]
  form: any
  name: string
  mainStyle?: CSSProperties
  checkboxDirection?: 'row' | 'column' | 'row-reverse' | 'column-reverse'
  style?: CSSProperties
  radiusFull?: boolean
}) => {
  const clicked = (value: string) => {
    const fieldValues = form.values[name] || []
    if (!fieldValues.includes(value)) {
      form.setFieldValue(name, [...fieldValues, value])
    } else {
      form.setFieldValue(
        name,
        fieldValues.filter((currentValue: string) => currentValue !== value),
      )
    }
  }

  return (
    <Row style={mainStyle} gap="md">
      {checkboxElement.map((element) => (
        <CheckboxItemWrapper
          justify="space-between"
          align="center"
          direction={checkboxDirection}
          key={element.value}
          style={style}
          gap="sm"
        >
          <CheckboxIcon
            checked={(form.values[name] || []).includes(element.value)}
            onChange={() => clicked(element.value)}
            radiusFull={radiusFull}
          />
          <Typography>{element.label}</Typography>
        </CheckboxItemWrapper>
      ))}
    </Row>
  )
}

const CheckboxIcon = ({
  checked,
  onChange,
  radiusFull = true,
}: CheckboxProps) => (
  <CheckboxWrapper onClick={onChange}>
    <HiddenCheckbox checked={checked} />
    <StyledCheckbox
      checked={checked}
      onChange={onChange}
      radiusFull={radiusFull}
    >
      <svg width="10px" height="8px" viewBox="0 0 10 8" onClick={onChange}>
        <path
          d="M1 3.5L3.5 6L9 1"
          stroke="white"
          strokeWidth="1.5"
          fill="none"
        />
      </svg>
    </StyledCheckbox>
  </CheckboxWrapper>
)

export default Checkbox

const CheckboxItemWrapper = styled(Row)<{
  direction: 'row' | 'column' | 'row-reverse' | 'column-reverse'
}>`
  flex-direction: ${(props) => props.direction};
`
