import axios from 'axios'
import { store, Actions } from '../store'
import { toast } from 'react-toastify'

const defaultHeader: any = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  'Api-Key': process.env.API_KEY,
}

const APP_BASE_URL: string = process.env.APP_BASE_URL || ''

const storeProcess = async (config: any, data: any) => {
  const actionType: 'set' | 'update' | 'remove' | 'reset' = config.action
  if (actionType !== 'reset') {
    store.dispatch(Actions[actionType](config.key, data))
  } else {
    store.dispatch(Actions['reset'](config.key))
  }
}

const request = async (configuration: any) => {
  let headers = defaultHeader

  const payload = {
    query: configuration.query,
    variables: configuration.variables || {},
  }

  return await axios
    .post(APP_BASE_URL, payload, {
      headers,
    })
    .then(async (resp) => {
      const data = resp?.data?.data || {}
      if (configuration?.config?.store) {
        await storeProcess(
          configuration?.config?.store,
          data[configuration?.func]?.data,
        )
      }
      if (configuration?.config?.successMsg) {
        toast.success(configuration?.config?.successMsg)
      }
      return data[configuration?.func]?.data || {}
    })
    .catch((err) => {
      if (configuration?.config?.showErr) {
        toast.error(
          err?.response?.data?.message ||
            err?.response?.data?.errors[0]?.message ||
            err?.message,
        )
      }
    })
}

export default request
