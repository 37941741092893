import theme from '@utils/theme'
import { Row } from './Grid'
import styled from '@emotion/styled'

interface SvgColor {
  svgColor?: keyof typeof theme.colors
  fill?: keyof typeof theme.colors
}
interface SvgProps extends SvgColor {
  src: string
  width?: number | string
  height?: number | string
  viewBox?: string
  onClick?: () => void
  size?: number | string
}

const SvgIcon: React.FC<SvgProps> = ({
  src,
  height,
  width,
  onClick,
  size,
  svgColor,
  fill,
  ...props
}) => {
  const iconSize = size ? { height: size, width: size } : { height, width }
  return (
    <Row style={iconSize} onClick={onClick}>
      <StyledSvg
        svgColor={svgColor || 'grey500'}
        fill={fill}
        style={{ height: '100%', width: '100%' }}
        {...props}
        dangerouslySetInnerHTML={{ __html: src }}
      />
    </Row>
  )
}

export default SvgIcon

const StyledSvg = styled.div<SvgColor>`
  display: flex;
  cursor: pointer;
  svg {
    color: ${(props) =>
      theme.colors[props.svgColor || props.svgColor]} !important;
    width: 100% !important;
    height: 100% !important;
    fill: ${(props) => theme.colors[props.fill || 'transparent']} !important;
  }
`
