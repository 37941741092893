import styled from '@emotion/styled'
import { PaginationListProps } from './interface'
import theme from '@utils/theme'
export const StyleTableWrapper = styled.div`
  display: block;
  width: 100%;
`

// export const TableHeaderWrapper = styled.div`
//   display: flex;
//   // background: ${theme.colors.white};
//   max-width: 100%;
//   padding: 10px;
//   box-shadow: 0px 0px 28px rgba(0, 0, 0, 0.04);
//   justify-content: space-between;
//   align-items: center;
//   margin-bottom: ${theme.spacing['1x']}px;
//   white-space: nowrap;
//   overflow-x: auto;
//   width: 100%;
//   @media (max-width: 1350px) {
//     width: fix-content;
//   }

//   @media (max-width: 980px) {
//     display: block;
//   }
// `
export const TableHeaderWrapper = styled.div`
  display: flex;
  padding: 10px 10px;
  justify-content: space-between;
  align-items: center;
  // margin-bottom: ${theme.spacing['xs']}px;
  white-space: nowrap;
`

export const StyleTable = styled.table<any>`
  background: ${theme.colors.white};
  box-shadow: 0px 0px 28px rgba(0, 0, 0, 0.04);
  border-collapse: collapse;
  max-width: 100%;

  overflow-x: auto;
  width: 100%;
  @media (max-width: 1350px) {
    width: fix-content;
  }

  @media (max-width: 980px) {
    display: block;
  }
`

export const StyleTableHead = styled.thead``
export const StyleTableBody = styled.tbody``
export const StyleTRow = styled.tr``
export const StyledTh = styled.th`
  background: ${theme.colors.primary50};
  padding: 16px;
  font-size: ${theme.fontSizes['md']}px;
  font-weight: ${theme.fontWeight['xlg']};
`

export const StyleTHead = styled.thead`
  width: 100%;
  background-color: ${theme.colors.primary50};
  font-size: ${theme.fontSizes['md']}px;
  color: ${theme.colors.gray400};
  background: ${theme.colors.foundationViolet};
  font-weight: 500;
  text-align: left;
  padding: 10px 10px;
  white-space: nowrap;
  @media (max-width: 720px) {
    font-size: ${theme.fontSizes['sm']}px;
  }
`

export const StyleTData = styled.td`
  // font-size: ${theme.fontSizes['md']}px;
  background: ${theme.colors.white};
  font-weight: ${theme.fontWeight['lg']};
  padding: 15px 10px;
  // color: ${theme.colors.gray400};
  ${(props) => `${props.style}`}
  // white-space: nowrap;
  @media (max-width: 720px) {
    font-size: ${theme.fontSizes['sm']}px;
  }
`

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`

export const Test = styled.div`
  // display: flex;
  // justify-content: flex-end;
  // margin-top: 20px;
`
export const PaginationOrderListWrapper = styled.ul`
  display: flex;
  column-gap: 10px;
  list-style: none;
`

export const PaginationList = styled.li<PaginationListProps>`
  cursor: pointer;
  font-size: ${theme.fontSizes.md}px;
  border: ${(props) =>
    !!props.border ? `1px solid ${theme.colors.primary}` : 'none'};
  display: flex;
  align-items: center;
  height: 30px;
  padding: 0px 15px;
`

export const Select = styled.select`
  outline: none;
  font-size: ${theme.fontSizes.sm}px;
  border-radius: 4px;
  height: 30px;
  border: 0px;
  width: 60px;
  color: ${theme.colors.gray400};
`

export const StyleLeftHeaderWrapper = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;
`

export const StyleRightHeaderWrapper = styled.div`
  margin-left: auto;
`

export const StyleSearchWrapper = styled.div`
  border: 1px solid #b9b9b9;
  position: relative;
  border-radius: 5px;
`

export const StyleSearchInput = styled.input`
  outline: none;
  font-size: ${theme.fontSizes.md}px;
  border-radius: 4px;
  background: ${theme.colors.white};
  text-indent: 45px;
  height: 35px;
  border: 0px;
  width: 250px;
  color: ${theme.colors.gray400};
`
export const StyleSearchIconWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  top: 0px;
  left: 0px;
  height: 35px;
  padding: 0px 15px;
`

export const StyledCheckbox = styled.input`
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: relative;
  appearance: none;
  border: 1px solid #ddd;
  border-radius: 4px;
  outline: none;
`
