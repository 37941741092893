import { useState } from 'react'
import { ChooseButtonProps } from './interface'
import { StyleButton, StyleChooseButtonWrapper } from './style'

const ChooseButton = ({
  data,
  select,
  onChange,
  form,
  name,
  value,
  buttonStyle,
}: ChooseButtonProps) => {
  const [selectedCount, setSelectedCount] = useState(0)
  const handleButtonClick = (item: any) => {
    const selectValue = !!select?.valueAsKey ? item[select?.valueAsKey] : null
    if (!!select?.type && select?.type === 'multiple') {
      const checkExist = value?.includes(selectValue)
      if (!!checkExist) {
        const updateValue = value?.filter((item: any) => item !== selectValue)
        form?.setFieldValue(name, updateValue)
        setSelectedCount(selectedCount - 1)
      } else {
        if (!select?.maxItems || value.length < select?.maxItems) {
          form?.setFieldValue(name, [...value, selectValue])
          setSelectedCount(selectedCount + 1)
        }
      }
    } else {
      form?.setFieldValue(name, selectValue)
    }
    onChange && onChange(selectValue)
  }

  const selectAll = () => {
    if (value.length === data?.length) {
      form?.setFieldValue(name, [])
    } else {
      form?.setFieldValue(
        name,
        data?.map((category) => category._id),
      )
    }
  }

  return (
    <StyleChooseButtonWrapper $multiLine={select?.$multiLine ?? 1}>
      {select?.showAllButton && (
        <StyleButton
          style={buttonStyle}
          active={value.length === data?.length ? 1 : 0}
          onClick={() => selectAll()}
        >
          All {name}
        </StyleButton>
      )}
      {!!data?.length &&
        data?.map((item: any, index: number) => {
          return (
            <StyleButton
              style={buttonStyle}
              active={
                value?.includes(
                  !!select?.valueAsKey ? item[select?.valueAsKey] : null,
                )
                  ? 1
                  : 0
              }
              onClick={() => handleButtonClick(item)}
              key={index}
            >
              {!!select?.renderIcon
                ? select?.renderIcon(item[select?.iconAsKey ?? ''])
                : item?.icon?.[0]?.xm}
              {!!select?.titleAsKey ? item[select.titleAsKey] : item.title}
            </StyleButton>
          )
        })}
    </StyleChooseButtonWrapper>
  )
}

export default ChooseButton
