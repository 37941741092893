import styled from '@emotion/styled'
import { CSSProperties } from 'react'
import { Column, Row } from './Grid'
import theme from '@utils/theme'
import SvgIcon from './SvgIcon'
import { arrowDown } from '@assets/svg'

const Accordion = ({
  expanded,
  setExpanded,
  childrenComponent,
  headerComponent,
  icon,
  headerStyle,
}: {
  expanded: boolean
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>
  childrenComponent: JSX.Element
  headerComponent: JSX.Element
  icon?: boolean | JSX.Element
  headerStyle?: CSSProperties
}) => {
  return (
    <>
      <StyledRow
        justify="space-between"
        align="center"
        onClick={() => setExpanded((prev) => !prev)}
        style={headerStyle}
      >
        {headerComponent}
        {icon && (
          <Arrow
            svgColor={expanded ? 'primary' : 'grey500'}
            src={arrowDown}
            expanded={expanded ? true : false}
          />
        )}
      </StyledRow>
      <AccordionContent expanded={expanded}>
        {childrenComponent}
      </AccordionContent>
    </>
  )
}

export default Accordion

const Arrow = styled(SvgIcon)<{ expanded: boolean }>`
  transform: ${(props) => (props.expanded ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 800ms ease-in-out;
`

export const StyledRow = styled(Row)`
  border: 1px solid ${theme.colors.red100};
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
`

const AccordionContent = styled(Column)<{ expanded: boolean }>`
  overflow: hidden;
  max-height: ${(props) => (props.expanded ? '500px' : '0')};
  transition:
    max-height 600ms ease-in-out,
    transform 600ms ease-in-out;
  transform: ${(props) => (props.expanded ? 'translateY(0)' : 'translateY(0)')};
`
