import { useState } from 'react'
import Button from '@components/Button'
import { Column, Row } from '@components/Grid'
import VehicleTypeForm from '@components/Forms/Vehicle/VehicleTypeForm'
import VehicleModelForm from '@components/Forms/Vehicle/VehicleModelForm'
import VehicleBrandForm from '@components/Forms/Vehicle/VehicleBrandForm'

const VehicleSetup = () => {
  const [activeButton, setActiveButton] = useState('Vehicle type')
  const buttonConfigs = [
    { title: 'Vehicle type', key: 'type' },
    { title: 'Vehicle brand', key: 'brand' },
    { title: 'Vehicle model', key: 'model' },
  ]
  const renderedForm = () => {
    switch (activeButton) {
      case 'Vehicle type':
        return <VehicleTypeForm />
      case 'Vehicle brand':
        return <VehicleBrandForm />
      case 'Vehicle model':
        return <VehicleModelForm />
      default:
        return null
    }
  }
  return (
    <Column gap="sm" width="100%">
      <Row width="100%" gap="sm">
        {buttonConfigs.map((button) => (
          <Button
            key={button.key}
            title={button.title}
            bgColor={activeButton === button.title ? 'primary50' : 'white'}
            textColor={activeButton === button.title ? 'primary' : 'grey400'}
            radius="sm"
            width="fit-content"
            onClick={() => setActiveButton(button.title)}
          />
        ))}
      </Row>
      <Row width="100%">{renderedForm()}</Row>
    </Column>
  )
}

export default VehicleSetup
