import React, { useState } from 'react'
import styled from '@emotion/styled'
// import { Row } from '@components/Grid'
import theme from '@utils/theme'

interface IToggleButton {
  size?: keyof typeof theme.fontSizes
  name: string
  onChange: (checked: boolean) => void
  defaultChecked?: boolean
}

interface StyledProps {
  size: keyof typeof theme.fontSizes
}

const ToggleButton = ({
  size = 'md',
  name,
  onChange,
  defaultChecked = false,
}: IToggleButton) => {
  const [checked, setChecked] = useState(defaultChecked)
  const handleChange = () => {
    setChecked((prevChecked) => !prevChecked)
    onChange(!checked)
  }
  return (
    <Switch size={size}>
      <Input
        type="checkbox"
        name={name}
        checked={checked}
        onChange={handleChange}
      />
      <Slider className="slider" />
    </Switch>
  )
}

export default ToggleButton

const Switch = styled.label<StyledProps>`
  position: relative;
  display: inline-block;
  width: 40px;  
  height: 22px;  
`

const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
  &:checked + .slider {
    background-color: ${theme.colors.green500}; 
  }
  &:focus + .slider {
    box-shadow: 0 0 1px ${theme.colors.primary};
  }
  &:checked + .slider:before {
    transform: translateX(18px); 
  }
`

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 22px;  
  &:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px; 
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
`

