import Button from '@components/Button'
import FormField from '@components/FormField'
import { Column, Row } from '@components/Grid'
import Typography from '@components/Typography'
import styled from '@emotion/styled'
import theme from '@utils/theme'
import { Form, Formik } from 'formik'

const DefaultPrice = () => {
  const fareCategories = [
    {
      vehicleType: 'Bike',
      fares: {
        baseFare: '250',
        farePerKm: '15',
        cancellationFee: '5',
        waitingDelayFee: '2',
      },
    },
    {
      vehicleType: 'Car',
      fares: {
        baseFare: '500',
        farePerKm: '25',
        cancellationFee: '10',
        waitingDelayFee: '3',
      },
    },
    {
      vehicleType: 'Auto',
      fares: {
        baseFare: '300',
        farePerKm: '20',
        cancellationFee: '8',
        waitingDelayFee: '4',
      },
    },
  ]
  const availableVehicles = [
    {
      label: 'Bike',
      value: 'bike',
    },
    {
      label: 'Car',
      value: 'car',
    },
    {
      label: 'Auto',
      value: 'auto',
    },
  ]

  return (
    <Column width="100%" gap="lg">
      <Typography fontColor="grey500" fontSize="2x" fontWeight='xlg'>
        Trip Fare Setup
      </Typography>
      <Column padding="lg" background="white" width="100%" gap="2x">
        <Typography fontColor="primary" fontWeight="2x" fontSize="lg">
          Free Default Price (Bagmati Zone)
        </Typography>

        <Formik initialValues={{}} onSubmit={() => {}}>
          <FormWrapper>
            <Row align="center" gap="1x">
              <div
                style={{
                  minWidth: 'fit-content ',
                  fontWeight: 700,
                  fontSize: '16px',
                }}
              >
                Available Category in this zone
              </div>
              <FormField
                name="parcelCategorires"
                type="checkbox"
                checkboxProps={{
                  checkboxElement: availableVehicles.map((element) => {
                    return {
                      label: (
                        <Typography key={element.value} fontSize="md">
                          {element.label}
                        </Typography>
                      ),
                      value: element.value,
                    }
                  }),
                }}
              />
            </Row>

            <Row gap="2x">
              <FormField type="text" label="Base Fare" name="basefare" />
              <FormField type="text" label="Fare (per km)" name="fareperkm" />
              <FormField
                type="text"
                label="Cancellation fee (%)"
                name="cancellationfee"
              />
            </Row>
          </FormWrapper>
        </Formik>

        {/* Table header */}
        <Wrapper align="center" justify="space-between">
          <TableHeader background="ghostWhite">
            <Typography fontSize="lg">Fare</Typography>
            <Typography>Base Fare (Rs)</Typography>
            <Typography>Fare per km (Rs)</Typography>
            <Typography>Cancellation Fee (%)</Typography>
            <Typography>Waiting Delay Fee (%)</Typography>
          </TableHeader>

          {fareCategories.map((category) => (
            <ColumnWrapper key={category.vehicleType}>
              <Typography align="center" fontSize="lg">
                {category.vehicleType}
              </Typography>
              <Button
                title={category.fares.baseFare}
                bgColor="transparent"
                border="grey50"
                paddingX="sm"
                width="50%"
              />
              <Button
                title={category.fares.farePerKm}
                bgColor="transparent"
                border="grey50"
                paddingX="sm"
                width="50%"
              />
              <Button
                title={category.fares.cancellationFee}
                bgColor="transparent"
                border="grey50"
                paddingX="sm"
                width="50%"
              />
              <Button
                title={category.fares.waitingDelayFee}
                bgColor="transparent"
                border="grey50"
                paddingX="sm"
                width="50%"
              />
            </ColumnWrapper>
          ))}
        </Wrapper>

        {/* Save button */}
        <Row width="100%" align="right" justify="right">
          <Button
            title="Save"
            bgColor="primary"
            textColor="white"
            width="15%"
          />
        </Row>
      </Column>
    </Column>
  )
}

export default DefaultPrice

const Wrapper = styled(Row)`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  place-items: center;
  align-items: center;
`

const ColumnWrapper = styled(Column)`
  width: 100%;
  gap: ${theme.spacing['2x']}px;
  padding: ${theme.spacing['2x']}px;
  align-items: center;
  justify-content: center;
  border: 1px solid ${theme.colors.grey50};
  border-right: none !important;
  border-left: none !important;
`
const FormWrapper = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`
const TableHeader = styled(ColumnWrapper)`
  width: 100%;
  height: 100%;
  justify-content: space-between;
  border: none;
  border-right: 1px solid ${theme.colors.grey50} !important;
`
