export const DASHBOARD = '/dashboard'
export const USER_MANAGEMENT = '/user-management'

export const CUSTOMER_MANAGEMENT = `${USER_MANAGEMENT}/customer`
export const CUSTOMER_MANAGEMENT_VIEW = `${USER_MANAGEMENT}/customer/view`

export const DRIVER_MANAGEMENT = `${USER_MANAGEMENT}/driver`
export const DRIVER_MANAGEMENT_VIEW = `${USER_MANAGEMENT}/driver/view`

export const DOCUMENT_SETUP = '/document-setup'
export const ZONE_SETUP = '/zone-setup'

export const TRIP_MANAGEMENT = '/trip-management'
export const TRIP_MANAGEMENT_VIEW = `/trip-management/trip/view`

export const VEHICLE_MANAGEMENT = '/vehicle-management'

export const VEHICE_LIST = `${VEHICLE_MANAGEMENT}/vehicle-list`
export const VEHICLE_SETUP = `${VEHICLE_MANAGEMENT}/vehicle-setup`

export const FARE_MANAGEMENT = '/fare-management'
export const TRIP_FARE_SETUP = `${FARE_MANAGEMENT}/trip-fare-setup`
export const PARCEL_FARE_SETUP = `${FARE_MANAGEMENT}/parcel-fare-setup`
export const DEFAULT_RIDE_PRICE = `${TRIP_FARE_SETUP}/default-ride-price`
export const DEFAULT_PARCEL_PRICE = `${TRIP_FARE_SETUP}/default-parcel-price`

export const PARCEL_SETUP = '/parcel-setup'

export const PROMOTION = '/promotion'
export const BANNER_SETUP = `${PROMOTION}/banner-setup`
export const COUPON = `${PROMOTION}/coupon`

export const PUSH_NOTIFICATIONS = '/push-notifications'

export const WALLET_MANAGEMENT = 'wallet-management'
export const TOPUP_LIST = `${WALLET_MANAGEMENT}/topup-list`
export const WITHDRAW_REQUEST = `${WALLET_MANAGEMENT}/Withdraw-request`

export const TRANSACTION_AND_REPORTS = '/transaction-and-request'
export const TRANSACTION_REQUEST = `${TRANSACTION_AND_REPORTS}/transaction`
export const REPORT = `${TRANSACTION_AND_REPORTS}/report`

export const PAGE_SETUP = '/page-setup'

export const BUSINESS_SETUP = '/business-setup'

export const SETTINGS = '/settings'
