import styled from '@emotion/styled'
import Typography, { TypographyProps } from './Typography'
import theme from '@utils/theme'

// Ensure PrimaryWord is displayed inline
const PrimaryWord = styled(Typography)<{
  highlightColor?: keyof typeof theme.colors
}>`
  color: ${(props) =>
    props.highlightColor
      ? theme.colors[props.highlightColor]
      : theme.colors.primary};
  display: inline;
`

interface HighlightedLastWordProps extends TypographyProps {
  text: string
  highlightCount?: number
  highlightColor?: keyof typeof theme.colors // Highlight color comes from theme.colors
}

const HighlightedLastWord = ({
  text,
  highlightCount = 1,
  highlightColor, // This will be a key from theme.colors
  ...props
}: HighlightedLastWordProps) => {
  const words = text.trim().split(' ')
  const highlightWords = words.splice(-highlightCount)
  const initialText = words.join(' ')

  return (
    <Typography {...props} style={{ display: 'inline' }}>
      {/* Ensure Typography is inline */}
      {initialText && <span>{initialText} </span>}
      {highlightWords.map((word, index) => (
        <PrimaryWord
          key={index}
          variant="span"
          {...props}
          highlightColor={highlightColor} // Pass the highlightColor as a theme key
        >
          {word}
          {index < highlightWords.length - 1 ? ' ' : ''}{' '}
        </PrimaryWord>
      ))}
    </Typography>
  )
}

export default HighlightedLastWord
