export type Sizes = {
  zero?: number
  xs: number
  sm: number
  md: number
  lg: number
  xlg?: number
  '1x': number
  '2x': number
  '3x': number
  '4x': number
  '5x'?: number
  '6x'?: number
  '7x'?: number
  auto: string
}

export const fontSizes: Sizes = {
  zero: 0,
  xs: 10,
  sm: 12,
  md: 14,
  lg: 16,
  '1x': 20,
  '2x': 24,
  '3x': 30,
  '4x': 40,
  '5x': 50,
  '6x': 60,
  '7x': 64,
  auto: 'auto',
}
export const spacing: Sizes = {
  zero: 0,
  xs: 4,
  sm: 8,
  md: 12,
  lg: 16,
  '1x': 20,
  '2x': 24,
  '3x': 30,
  '4x': 40,
  '5x': 50,
  '6x': 60,
  '7x': 70,
  auto: 'auto',
}

export const colors: any = {
  primary: '#E72929',
  primary50: '#fdeaea',
  white: '#fff',
  white100: '#FCFCFC',
  white200: '#fafafa',
  white300: '#f8f8f8',
  black: '#000',
  bodyColor: '#F8F8FF',
  grey50: '#EBEBEB',
  grey200: '#A1A1A1',
  grey400: '#5C5C5C',
  grey300: '#767676',
  grey500: '#333333',
  red100: '#F8BDBD',
  green50: '#EDF7EE',
  lightCyan: '#ECFEFF',
  lightBlue: '#EFF6FF',
  green200: '#ADDAAF',
  green500: '#4caf50',
  paleGreen: '#ECFCCB',
  skyBlueMist: '#EEF2Ff',
  butterCream: '#FFEFC6',
  skyBlue: '#63d1e4',
  lightSky: '#D9FCFF',
  SkyDark: '#06B6D4',
  ghostWhite: '#F8F8FF;',
}
export const fontFamily: any = {
  primary: 'Poppins',
  secondary: 'Montserrat',
}

export const fontWeight: Sizes = {
  xs: 100,
  sm: 200,
  md: 300,
  lg: 400,
  xlg: 500,
  '1x': 600,
  '2x': 700,
  '3x': 800,
  '4x': 900,
  '5x': 0,
  auto: 'auto',
}

export const createTheme = () => {
  const theme = {
    colors: colors,
    fontSizes,
    spacing,
    fontFamily,
    fontWeight,
  }

  return theme
}

const theme = createTheme()
export default theme
