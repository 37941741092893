import { Column } from '@components/Grid'
import Dashcard, { dashcardData } from '@components/Dashcard'
import HighlightedLastWord from '@components/HighlightedLastWord'
import { DashboardTable } from './dashboardTable'
const Dashboard = () => {
  return (
    <Column align="center" justify="center" width="100%" gap="lg">
      <HighlightedLastWord
        fontSize="lg"
        fontWeight="1x"
        text="Good morning, Alisha Thapa"
        highlightCount={2}
      />
      <Dashcard data={dashcardData} />
      <div
        style={{
          width: '100%',
          display: 'grid',
        }}
      >
        <DashboardTable />
      </div>
    </Column>
  )
}

export default Dashboard
