import { Column } from '@components/Grid'
import { useState } from 'react'
import { ChangeEvent } from 'react'
import { StyledPasswordIconWrapper, StyleInput } from './style'
import SvgIcon from '@components/SvgIcon'
import { view, viewOff } from '@assets/svg'

interface PasswordProps {
  name: string
  type: string
  placeholder: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  value?: string
}
const Password = ({ type, ...restProps }: PasswordProps) => {
  const [show, setShow] = useState<boolean>(false)
  return (
    <Column align="center" justify="center" style={{ position: 'relative' }}>
      <StyleInput {...restProps} type={!!show ? 'text' : type} />
      <StyledPasswordIconWrapper
        onClick={() => {
          setShow(!show)
        }}
      >
        <SvgIcon src={show ? view : viewOff} height={24} width={24} />
      </StyledPasswordIconWrapper>
    </Column>
  )
}
export default Password
