import styled from '@emotion/styled'
import { CSSProperties, ReactNode } from 'react'
import { theme } from '../utils'

export interface TypographyProps {
  fontSize?: keyof typeof theme.fontSizes
  fontColor?: keyof typeof theme.colors
  margin?: keyof typeof theme.spacing
  fontWeight?: keyof typeof theme.fontWeight
  lineHeight?: keyof typeof theme.spacing
  // lineHeight?: string
  variant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'p'
    | 'span'
    | 'strong'
    | 'div'
  style?: CSSProperties
  content?: string
  children?: ReactNode
  onClick?: (() => void) | ((e: React.FormEvent<HTMLElement>) => void)
  align?: 'left' | 'right' | 'center'
  id?: string
}

const baseStyles = (props: TypographyProps) => `
  margin: 0;
  font-size: ${theme.fontSizes[props?.fontSize || 'md']}px;
  font-weight: ${theme.fontWeight[props?.fontWeight || 'lg']};
  color: ${theme.colors[props?.fontColor || 'grey500']};
  text-align: ${props.align || 'left'};
  line-height: ${theme.spacing[props?.lineHeight || '']}px;
  // line-height: ${props?.style?.lineHeight || ''}px;
  width:100%;
  ${
    props?.style &&
    Object.entries(props?.style)
      .map(([key, value]) => `${key}: ${value};`)
      .join(' ')
  }
`

export const StyleH1 = styled.h1<TypographyProps>`
  ${baseStyles}
  font-size: ${(props) => theme.fontSizes[props.fontSize || '4x']}px;
`

export const StyleH2 = styled.h2<TypographyProps>`
  ${baseStyles}
  font-size: ${(props) => theme.fontSizes[props.fontSize || '3x']}px;
`

export const StyleH3 = styled.h3<TypographyProps>`
  ${baseStyles}
  font-size: ${(props) => theme.fontSizes[props.fontSize || '2x']}px;
`

export const StyleH4 = styled.h4<TypographyProps>`
  ${baseStyles}
  font-size: ${(props) => theme.fontSizes[props.fontSize || '1x']}px;
`

export const StyleH5 = styled.h5<TypographyProps>`
  ${baseStyles}
  font-size: ${(props) => theme.fontSizes[props.fontSize || 'lg']}px;
`

export const StyleH6 = styled.h6<TypographyProps>`
  ${baseStyles}
  font-size: ${(props) => theme.fontSizes[props.fontSize || 'md']}px;
`

export const StyleDiv = styled.div<TypographyProps>`
  ${baseStyles}
  font-size: ${(props) => theme.fontSizes[props.fontSize || 'lg']}px;
`

export const StyleSpan = styled.span<TypographyProps>`
  ${baseStyles}
  font-size: ${(props) => theme.fontSizes[props.fontSize || 'md']}px;
  display: inline;
`

export const StyleStrong = styled.strong<TypographyProps>`
  ${baseStyles}
  font-size: ${(props) => theme.fontSizes[props.fontSize || 'md']}px;
  display: inline;
`

export const StyleParagraph = styled.p<TypographyProps>`
  ${baseStyles}
  font-size: ${(props) => theme.fontSizes[props.fontSize || 'md']}px;
`

const Typography = ({
  variant,
  content,
  children,
  id,
  ...restProps
}: TypographyProps) => {
  switch (variant) {
    case 'h1':
      return (
        <StyleH1 {...restProps} id={id}>
          {children || content}
        </StyleH1>
      )
    case 'h2':
      return (
        <StyleH2 {...restProps} id={id}>
          {children || content}
        </StyleH2>
      )
    case 'h3':
      return (
        <StyleH3 {...restProps} id={id}>
          {children || content}
        </StyleH3>
      )
    case 'h4':
      return (
        <StyleH4 {...restProps} id={id}>
          {children || content}
        </StyleH4>
      )
    case 'h5':
      return (
        <StyleH5 {...restProps} id={id}>
          {children || content}
        </StyleH5>
      )
    case 'h6':
      return (
        <StyleH6 {...restProps} id={id}>
          {children || content}
        </StyleH6>
      )
    case 'span':
      return (
        <StyleSpan {...restProps} id={id}>
          {children || content}
        </StyleSpan>
      )
    case 'strong':
      return (
        <StyleStrong {...restProps} id={id}>
          {children || content}
        </StyleStrong>
      )
    case 'div':
      return (
        <StyleDiv {...restProps} id={id}>
          {children || content}
        </StyleDiv>
      )
    default:
      return (
        <StyleParagraph {...restProps} id={id}>
          {children || content}
        </StyleParagraph>
      )
  }
}

export default Typography
