import { TableHeadProps } from './interface'
import { StyledTh } from './style'

const TableHead = ({ items }: TableHeadProps) => {
  return (
    <>
      {items?.map((item: any) => (
        <StyledTh key={item?.key}>{item?.title}</StyledTh>
      ))}
    </>
  )
}

export default TableHead
