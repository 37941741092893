import Button from '@components/Button'
import FormField from '@components/FormField'
import { Column, Row } from '@components/Grid'
import Typography from '@components/Typography'
import styled from '@emotion/styled'
import { Form, Formik } from 'formik'
import DataTable from '@components/DataTable'
import Breadcrum from '@components/Breadcrum'
import SvgIcon from '@components/SvgIcon'
import {
  bikeIcon,
  trashBin,
  carIcon,
  arrowDownload,
  plusIcon,
  pencilEdit,
  testIcon,
} from '@assets/svg'
import { ButtonsProps } from '@components/Breadcrum/interface'
import ToggleButton from '@components/FormField/ToggleButton'
const PushNotifications = () => {
  const columns = [
    {
      title: 'User',
      key: 'caption',
      render: (values: any) => {
        const caption = values?.row?.user ?? 'N/A'
        const truncatedName =
          caption?.length > 20 ? caption?.slice(0, 40) + '...' : caption
        return (
          <Row gap="sm" justify="center">
            {truncatedName}
          </Row>
        )
      },
    },
    {
      title: 'Banner Image',
      key: 'caption',
      render: (values: any) => {
        const caption = values?.row?.totalTrip ?? 'N/A'
        const truncatedName =
          caption?.length > 20 ? caption?.slice(0, 40) + '...' : caption
        return (
          <Row justify="center">
            <Column>
              <SvgIcon src={caption} />
            </Column>
          </Row>
        )
      },
    },
    {
      title: 'Title',
      key: 'caption',
      render: (values: any) => {
        const caption = values?.row?.name ?? 'N/A'
        const truncatedName =
          caption?.length > 20 ? caption?.slice(0, 40) + '...' : caption
        return (
          <Row gap="sm" justify="center">
            {truncatedName}
          </Row>
        )
      },
    },
    {
      title: 'Body',
      key: 'caption',
      render: (values: any) => {
        const caption = values?.row?.body ?? 'N/A'
        const truncatedName =
          caption?.length > 20 ? caption?.slice(0, 40) + '...' : caption
        return (
          <Row gap="sm" justify="center">
            {truncatedName}
          </Row>
        )
      },
    },

    {
      title: 'Promos',
      key: 'caption',
      render: (values: any) => {
        const caption = values?.row?.promos ?? 'N/A'
        const truncatedName =
          caption?.length > 20 ? caption?.slice(0, 40) + '...' : caption
        return (
          <Row gap="sm" justify="center">
            {truncatedName}
          </Row>
        )
      },
    },
    {
      title: 'Status',
      key: 'tripStatus',
      render: (values: any) => {
        const CustomerName = values?.row?.TripStatus ?? 'N/A'
        let textColor
        let bgColor = 'primary50'

        switch (CustomerName) {
          case 'Pending':
          case 'Cancelled':
            textColor = 'primary'
            break
          case 'Ongoing':
            textColor = 'SkyDark'
            bgColor = 'lightSky'
            break
          case 'Completed':
            textColor = 'green500'
            bgColor = 'green50'
            break
          default:
            textColor = 'primary'
        }

        return (
          <>
            <Row gap="sm" justify="center">
              <ToggleButton
                name="exampleToggle"
                defaultChecked={true}
                onChange={(checked) => console.log('Toggle is now:', checked)}
              />
            </Row>
          </>
        )
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (values: any) => {
        return (
          <Row gap="sm" justify="center">
            <SvgIcon src={pencilEdit} />
            <SvgIcon src={trashBin} />
          </Row>
        )
      },
    },
  ]

  const buttons: ButtonsProps[] = [
    {
      title: 'All',
      type: 'button',
      outline: 'true',
      color: 'primary',
      textColor: 'primary',
      wrapperPadding: '4px 0px 0px 0px',
      bgColor: 'primary50',
    },
    {
      title: 'Active',
      type: 'button',
      outline: 'true',
      color: 'primary',
      border: 'grey50',
      textColor: 'grey400',
      wrapperPadding: '4px 0px 0px 0px',
      bgColor: 'white100',
    },

    {
      title: 'InActive',
      type: 'button',
      outline: 'true',
      color: 'primary',
      border: 'grey50',
      textColor: 'grey400',
      bgColor: 'white100',
      wrapperPadding: '4px 0px 0px 0px',
    },
    {
      title: 'Download',
      type: 'button',
      outline: 'true',
      border: 'primary',
      color: 'primary',
      frontIcon: <SvgIcon src={arrowDownload} />,
      textColor: 'primary',
      wrapperPadding: '0px 0px 0px 20px',
      bgColor: 'white100',
    },
    {
      title: 'Add New Vehicle',
      type: 'button',
      outline: 'true',
      border: 'primary',
      color: 'primary',
      frontIcon: <SvgIcon src={plusIcon} />,
      textColor: 'white',
      bgColor: 'primary',
    },
  ]

  const staticData = [
    {
      name: 'Dashain Offer',
      user: 'New',
      body: 'Get Dashain Offer',
      promos: 'Promo',
      registerDate: 'August 1 2024',
      totalTrip: carIcon,
      redirectLink: 'www.iride.com',
      status: 'Active',
    },
    {
      name: 'Dashain Offer',
      user: 'New',
      body: 'Get Dashain Offer',
      promos: 'Promo',
      registerDate: 'August 1 2024',
      totalTrip: bikeIcon,
      redirectLink: 'www.iride.com',
      status: 'Active',
    },
  ]
  const handleSubmit = async (values, { resetForm }) => {
    resetForm()
  }

  return (
    <>
      <Column gap="md" width="100%">
        <Column
          background="white"
          padding="lg"
          radius="sm"
          gap="lg"
          width="100%"
        >
          <Typography fontColor="grey500" fontSize="2x" fontWeight="xlg">
            Push Notifications
          </Typography>
          <Formik
            initialValues={{ vehicle: '', image: null, vehicleType: '' }}
            onSubmit={handleSubmit}
          >
            <FormWrapper>
              <Row width="100%" gap="2x">
                <Column width="50%" gap="lg">
                  <FormField
                    label="Select Users"
                    type="select"
                    name="selectUsers"
                    placeholder="Select users"
                    select={{
                      titleAsKey: 'label',
                      valueAsKey: 'value',
                    }}
                    data={[
                      { value: 'new', label: 'New' },
                      { value: 'existing', label: 'Existing' },
                    ]}
                  />
                  <FormField
                    type="text"
                    label="Title"
                    placeholder="Type your text"
                    name="title"
                  />
                  <FormField
                    type="text"
                    label="Body"
                    placeholder="Type your text"
                    name="body"
                  />
                </Column>
                <Column width="50%" gap="lg">
                  <FormField
                    label="Upload Image"
                    type="file"
                    name="image"
                    file={{ type: 'single', fileStyle: 'button' }}
                  />
                  <FormField
                    label="Select Promos"
                    type="select"
                    name="selectPromos"
                    placeholder="Select promos"
                    select={{
                      titleAsKey: 'label',
                      valueAsKey: 'value',
                    }}
                    data={[
                      { value: 'data1', label: 'Optoin 1' },
                      { value: 'data2', label: 'Option 2' },
                    ]}
                  />
                </Column>
              </Row>

              <Row width="100%" justify="flex-end">
                <Button
                  title="Add"
                  width="15%"
                  radius="sm"
                  textColor="white"
                  type="submit"
                  bgColor="primary"
                />
              </Row>
            </FormWrapper>
          </Formik>
        </Column>
        <Typography fontColor="grey500" fontSize="2x" fontWeight="xlg">
          Push Notification List
        </Typography>
        <DataTable
          data={staticData}
          search
          columns={columns}
          onFilterChange={(filter) => console.log('Filter changed:', filter)}
          breadcrum={<Breadcrum title="" buttons={buttons} />}
        />
      </Column>
    </>
  )
}

export default PushNotifications

const FormWrapper = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`
