import { ChangeEvent, useState } from 'react'

import { FileUploadProps } from './interface'
import {
  StyleFileUploadButtonLabel,
  StyleImageLabel,
  StyleMultipleImageItem,
  StyleMultipleImageRemoveWrapper,
  StylePreviewImage,
  StyleSingleImageWrapper,
} from './style'
import { Row } from '@components/Grid'
import SvgIcon from '@components/SvgIcon'
import { cancelCircle } from '@assets/svg'

const FileUploadForMobile = ({
  file,
  onChange,
  name,
  form,
  value,
  onlyVideo = false,
}: FileUploadProps) => {
  const [base64Url, setBase64Url] = useState<any>(undefined)
  const reader = new FileReader()
  const includeVideo = (Array.isArray(value) ? value : [])?.some((file: File) =>
    file.type.startsWith('video/'),
  )

  const readFile = (data: File, type: string) => {
    if (reader.readyState === FileReader.LOADING) {
      reader.abort()
    }
    reader.onload = (e) => {
      const result = e?.target?.result
      const baseUrlName = `${name}BaseUrl`
      if (type === 'multiple') {
        const updateBaseUrl = base64Url?.length
          ? [...base64Url, result]
          : [result]
        setBase64Url(updateBaseUrl)
        form.setFieldValue(baseUrlName, updateBaseUrl)
      } else {
        setBase64Url(result)
        form.setFieldValue(baseUrlName, result)
      }
    }

    reader.onerror = () => {
      console.error('Error reading file')
    }

    reader.readAsDataURL(data)

    if (type === 'multiple') {
      const updateValue = value?.length ? [...value, data] : [data]
      form.setFieldValue(name, updateValue)
    } else {
      form.setFieldValue(name, data)
    }
  }

  const handleRemoveFile = (i: number) => {
    const updatedBase64Url = base64Url?.filter(
      (_: any, index: number) => i !== index,
    )
    const updatedValue = value?.filter((_: any, index: number) => i !== index)
    const baseUrlName = `${name}BaseUrl`

    if (updatedBase64Url.length === 0) {
      setBase64Url(null)
    } else {
      setBase64Url(updatedBase64Url)
    }

    form.setFieldValue(baseUrlName, updatedBase64Url)
    form.setFieldValue(name, updatedValue)
  }
  return (
    <>
      <StyleSingleImageWrapper>
        {file?.fileStyle === 'button' && (
          <Row justify="center">
            <StyleFileUploadButtonLabel
              htmlFor={name}
              isMobile={file?.isMobile}
            >
              {file?.title}
            </StyleFileUploadButtonLabel>
          </Row>
        )}
        <StyleImageLabel>
          {base64Url &&
            (typeof base64Url === 'string' ? (
              <StyleMultipleImageItem>
                <StylePreviewImage
                  width={200}
                  height={200}
                  src={base64Url}
                  alt="Preview"
                />
                <StyleMultipleImageRemoveWrapper
                  onClick={() => {
                    form.setFieldValue(name, [])
                    form.setFieldValue(`${name}BaseUrl`, undefined)
                    setBase64Url(undefined)
                  }}
                >
                  {/* <FaTimesCircle size={18} /> */}
                </StyleMultipleImageRemoveWrapper>
              </StyleMultipleImageItem>
            ) : (
              Array.isArray(base64Url) &&
              base64Url.map((item: string) => {
                return (
                  <StyleMultipleImageItem key={item}>
                    <StylePreviewImage
                      key={item}
                      width={200}
                      height={200}
                      src={item}
                      alt="Preview"
                    />
                    <StyleMultipleImageRemoveWrapper
                      onClick={() => handleRemoveFile(0)}
                    >
                      <SvgIcon src={cancelCircle} size={18} />
                    </StyleMultipleImageRemoveWrapper>
                  </StyleMultipleImageItem>
                )
              })
            ))}
        </StyleImageLabel>
        <input
          accept={`${includeVideo ? 'image/*' : onlyVideo ? 'video/*' : 'image/*, video/*, application/pdf'}`}
          style={{ display: 'none', zIndex: '0' }}
          type="file"
          name={name}
          id={name}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            const file = event?.target?.files?.[0]
            if (file) {
              readFile(file, file.type)
            }
            onChange && onChange(event)
            ;(event.target as HTMLInputElement).value = ''
          }}
        />
      </StyleSingleImageWrapper>
    </>
  )
}

export default FileUploadForMobile
