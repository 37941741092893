import { ChangeEvent } from 'react'
import { StyleTextArea } from './style'
import { Row } from '@components/Grid'
interface TextareaProps {
  value: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
  border?: string
  disabled?: boolean
  height?: string
}
const Textarea = ({
  value,
  onChange,
  placeholder,
  border,
  disabled,
  height,
}: TextareaProps) => {
  return (
    <Row width="100%">
      <StyleTextArea
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          onChange && onChange(event)
        }}
        rows={2}
        placeholder={placeholder || ''}
        value={value}
        border={border}
        disabled={disabled}
        height={height}
      />
    </Row>
  )
}

export default Textarea
