import { Column } from '@components/Grid'
import styled from '@emotion/styled'

interface SearchDropdownProps {
  name: string
  expanded: boolean
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>
  childrenComponent: JSX.Element
  onChange: (values: any) => void
  value: any
  placeholder?: string
}

const SearchDropdown = ({
  name,
  expanded,
  setExpanded,
  childrenComponent,
  onChange,
  value,
  placeholder,
}: SearchDropdownProps) => {
  return (
    <div style={{ position: 'relative' }}>
      <IconInputField
        name={name}
        onChange={onChange}
        value={value}
        setIsActive={setExpanded}
        placeholder={placeholder}
      />
      <DropDownWrapper expanded={expanded ? 1 : 0}>
        <DropDownComponent expanded={expanded ? 1 : 0}>
          {childrenComponent}
        </DropDownComponent>
      </DropDownWrapper>
    </div>
  )
}

export default SearchDropdown

const DropDownWrapper = styled(Column)<{ expanded: 0 | 1 }>`
  position: absolute;
  z-index: 10;
  background: transparent;
  width: 100%;
`

const DropDownComponent = styled(Column)<{ expanded: 0 | 1 }>`
  overflow: hidden;
  max-height: ${(props) => (props.expanded ? '500px' : '0')};
  transition: max-height 600ms ease-in-out;
`
