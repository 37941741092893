import { Column } from '@components/Grid'
import OperationZoneFareSetup from '@components/OperationZoneFareSetup'
import Typography from '@components/Typography'
import { DEFAULT_RIDE_PRICE } from '@constants/routeConstant'
import { useNavigate } from 'react-router-dom'
const zones = [
  {
    totalDrivers: 15,
    zoneName: 'Kathmandu',
  },
  {
    totalDrivers: 25,
    zoneName: 'Pokhara',
  },
]
const TripFareSetup = () => {
  const navigate = useNavigate()
  const handleClick = () => {
    navigate(DEFAULT_RIDE_PRICE)
  }
  return (
    <Column width="100%" gap="lg">
      <Typography fontColor="grey500" fontSize="2x" fontWeight='xlg'>
        Trip Fare Setup
      </Typography>
      <OperationZoneFareSetup
        zones={zones}
        isParcel={false}
        handleClick={handleClick}
      />
    </Column>
  )
}

export default TripFareSetup
