import ReactDOM from 'react-dom/client'
import App from './App'
import { Provider } from 'react-redux'
import { store } from './store'
import { theme } from './utils'
import { ThemeProvider } from '@emotion/react'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
import './global.css'
root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      {/* <ToastContainer /> */}
      <App />
    </ThemeProvider>
  </Provider>,
)
