import styled from '@emotion/styled'
import theme from '@utils/theme'
import { useState, useEffect } from 'react'
import { SelectInputProps } from './interface'

const StyleSelectInputWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;

  &::after {
    border: solid black;
    border-width: 0 2px 2px 0;
    margin: 0 16px;
    content: '';
    position: absolute;
    right: 0px;
    top: 35%;
    padding: 2px;
    height: 2px;
    width: 2px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
`

const StyleOption = styled.option`
  outline: none;
`

const StyleSelect = styled.select`
  position: relative;
  appearance: none;
  border: 1px solid ${theme.colors.grey50};
  padding: 12px;
  border-radius: ${theme.spacing['xs']}px;
  color: ${theme.colors.grey200};

  width: 100%;
  &:focus-visible {
    outline: none;
  }
`

const Select = ({
  data,
  value,
  onChange,
  select,
  placeholder = 'Choose an option',
  name,
  form,
  style,
}: SelectInputProps) => {
  const [selectedValue, setSelectedValue] = useState<string | undefined>(value)

  useEffect(() => {
    // If no value is passed and no option is selected, placeholder is shown
    if (!value && data && data.length > 0) {
      setSelectedValue('')
    }
  }, [data, value])

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = event.target.value
    setSelectedValue(selectedOption)
    onChange && onChange(event)
  }

  return (
    <StyleSelectInputWrapper>
      <StyleSelect
        value={selectedValue !== '' ? selectedValue : ''}
        onChange={handleSelectChange}
      >
        {/* Default Placeholder Option */}
        <StyleOption value={''} disabled hidden>
          {placeholder}
        </StyleOption>
        {/* Render actual options */}
        {!!data?.length &&
          data?.map((option) => (
            <StyleOption
              key={option[select?.valueAsKey ?? '']}
              value={option[select?.valueAsKey ?? '']}
            >
              {option[select?.titleAsKey ?? '']}
            </StyleOption>
          ))}
      </StyleSelect>
    </StyleSelectInputWrapper>
  )
}

export default Select
