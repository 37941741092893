import React from 'react'
import styled from '@emotion/styled'
import theme from '@utils/theme'

interface ImageProps {
  src: string
  alt: string
  size?: number | string
  width?: number | string
  height?: number | string
  borderRadiusFull?: boolean
  objectFit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down'
  objectPosition?: string
  borderRadius?: keyof typeof theme.spacing
}

const StyledImage = styled.img<ImageProps>`
  width: ${(props) => props.size || props.width || 'auto'};
  height: ${(props) => props.size || props.height || 'auto'};
  min-width: ${(props) => props.size || 'auto'};
  min-height: ${(props) => props.size || 'auto'};
  border-radius: ${(props) =>
    props.borderRadiusFull
      ? '50%'
      : props.borderRadius
        ? `${theme.spacing[props.borderRadius]}px`
        : '0'};
  object-fit: ${(props) => props.objectFit || 'cover'};
  object-position: ${(props) => props.objectPosition || 'center'};
`

const Image: React.FC<ImageProps> = ({
  src,
  alt,
  size,
  width,
  height,
  borderRadiusFull = false,
  objectFit = 'cover',
  objectPosition = 'center',
  ...props
}) => {
  const imageSize = size ? { width: size, height: size } : { width, height }

  return (
    <StyledImage
      src={src}
      alt={alt}
      {...imageSize}
      borderRadiusFull={borderRadiusFull}
      objectFit={objectFit}
      objectPosition={objectPosition}
      {...props}
    />
  )
}

export default Image
