import { request } from '../../utils'
import { LOGIN } from './query'
import { ReturnType } from '../types'

export const login = async (variables: {
  phoneNo: string
  password: string
}) => {
  try {
    const config: ReturnType = {
      query: LOGIN,
      variables: {
        input: {
          phoneNo: variables.phoneNo,
          password: variables.password,
        },
      },
      func: 'adminLogin',
      config: {
        store: {
          action: 'set',
          key: 'user',
        },
        successMsg: 'Login successful!',
      },
    }
    const response = await request(config)
    return response
  } catch (err: any) {
    throw new Error(err.message)
  }
}
