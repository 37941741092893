import { arrowDownload, eyeIcon, plusIcon, trashBin } from '@assets/svg'
import Breadcrum from '@components/Breadcrum'
import { ButtonsProps } from '@components/Breadcrum/interface'
import DataTable from '@components/DataTable'
import ToggleButton from '@components/FormField/ToggleButton'
import PromotionForm from '@components/Forms/PromotionForm'
import { Column, Row } from '@components/Grid'
import SvgIcon from '@components/SvgIcon'
import Typography from '@components/Typography'
const columns = [
  {
    title: 'Coupon Title',
    key: 'captionTitle',
    render: (values: any) => {
      const caption = values?.row?.couponTitle ?? 'N/A'
      return <Typography align="center">{caption}</Typography>
    },
  },
  {
    title: 'Coupon Code',
    key: 'couponCode',
    render: (values: any) => {
      const couponCode = values?.row?.couponCode ?? 'N/A'
      return (
        <Row gap="sm" justify="center">
          {couponCode}
        </Row>
      )
    },
  },
  {
    title: 'User Type',
    key: 'userType',
    render: (values: any) => {
      const userType = values?.row?.userType ?? 'N/A'
      return (
        <Row gap="sm" justify="center">
          {userType}
        </Row>
      )
    },
  },
  {
    title: 'Discount Type',
    key: 'discountType',
    render: (values: any) => {
      const discountType = values?.row?.discountType ?? 'N/A'
      return (
        <Row gap="sm" justify="center">
          {discountType}
        </Row>
      )
    },
  },
  {
    title: 'Discount Amount',
    key: 'discountAmount',
    render: (values: any) => {
      const discountAmount = values?.row?.discountAmount ?? 'N/A'
      return (
        <Row align="center" justify="center">
          <Typography align="center">{discountAmount}</Typography>
        </Row>
      )
    },
  },
  {
    title: 'Zone',
    key: 'zone',
    render: (values: any) => {
      const zone = values?.row?.zone ?? 'N/A'
      return (
        <Row gap="sm" justify="center">
          {zone}
        </Row>
      )
    },
  },
  {
    title: 'No.of Users ',
    key: 'noOfUsers',
    render: (values: any) => {
      const noOfUsers = values?.row?.noOfUsers ?? 'N/A'
      return (
        <Row gap="sm" justify="center">
          {noOfUsers}
        </Row>
      )
    },
  },
  {
    title: 'Duration',
    key: 'duration',
    render: (values: any) => {
      const startDate = values?.row?.duration.startDate ?? 'N/A'
      const endDate = values?.row?.duration.endDate ?? 'N/A'

      return (
        <Column gap="sm" justify="center" width="115px">
          <Typography>Start: {startDate}</Typography>
          <Typography>End :{endDate}</Typography>
        </Column>
      )
    },
  },
  {
    title: 'Status',
    key: 'status',
    render: (values: any) => {
      return (
        <Row gap="sm" justify="center">
          <ToggleButton
            name="exampleToggle"
            defaultChecked={true}
            onChange={(checked) => console.log('Toggle is now:', checked)}
          />
        </Row>
      )
    },
  },

  {
    title: 'Action',
    key: 'action',
    render: (values: any) => {
      return (
        <Row gap="sm" justify="center">
          <div>
            <SvgIcon src={eyeIcon} />
          </div>
          <SvgIcon src={trashBin} />
        </Row>
      )
    },
  },
]

const buttons: ButtonsProps[] = [
  {
    title: 'All',
    type: 'button',
    outline: 'true',
    color: 'primary',
    textColor: 'primary',
    wrapperPadding: '4px 0px 0px 0px',
    bgColor: 'primary50',
  },
  {
    title: 'Active',
    type: 'button',
    outline: 'true',
    color: 'primary',
    border: 'grey50',
    textColor: 'grey400',
    wrapperPadding: '4px 0px 0px 0px',
    bgColor: 'white100',
  },
  {
    title: 'Inactive',
    type: 'button',
    outline: 'true',
    color: 'primary',
    border: 'grey50',
    textColor: 'grey400',
    wrapperPadding: '4px 0px 0px 0px',
    bgColor: 'white100',
  },

  {
    title: 'Download',
    type: 'button',
    outline: 'true',
    border: 'primary',
    color: 'primary',
    frontIcon: <SvgIcon src={arrowDownload} />,
    textColor: 'primary',
    wrapperPadding: '0px 0px 0px 20px',
    bgColor: 'white100',
  },
  {
    title: 'Add New',
    type: 'button',
    outline: 'true',
    border: 'primary',
    color: 'primary',
    frontIcon: <SvgIcon src={plusIcon} />,
    textColor: 'white',
    bgColor: 'primary',
  },
]

const staticData = [
  {
    couponTitle: '30% off on Electronics',
    couponCode: 'ELEC30',
    discountType: 'Percentage',
    discountAmount: '30%',
    zone: 'Zone 1',
    noOfUsers: 100,
    duration: {
      startDate: 'Aug 6,2024',
      endDate: 'Aug 8, 2024',
    },
    status: 'Active',
    userType: 'New',
  },
  {
    couponTitle: '15% off on Groceries',
    couponCode: 'GROC15',
    discountType: 'Percentage',
    discountAmount: '15%',
    zone: 'Zone 2',
    noOfUsers: 200,
    duration: {
      startDate: 'Aug 6,2024',
      endDate: 'Aug 8, 2024',
    },
    status: 'Ongoing',
    userType: 'New',
  },
  {
    couponTitle: '$10 off on Clothing',
    couponCode: 'CLOTH10',
    discountType: 'Fixed Amount',
    discountAmount: '$10',
    zone: 'Zone 3',
    noOfUsers: 50,
    duration: {
      startDate: 'Aug 6,2024',
      endDate: 'Aug 8, 2024',
    },
    status: 'Expired',
    userType: 'Existing',
  },
  {
    couponTitle: '50% off on Home Appliances',
    couponCode: 'HOME50',
    discountType: 'Percentage',
    discountAmount: '50%',
    zone: 'Zone 1',
    noOfUsers: 150,
    duration: {
      startDate: 'Aug 6,2024',
      endDate: 'Aug 8, 2024',
    },
    status: 'Pending',
    userType: 'Existing',
  },
  {
    couponTitle: 'Free Shipping on Orders Above $50',
    couponCode: 'SHIPFREE',
    discountType: 'Free Shipping',
    discountAmount: 'N/A',
    zone: 'Zone 2',
    noOfUsers: 500,
    duration: {
      startDate: 'Aug 6,2024',
      endDate: 'Aug 8, 2024',
    },
    status: 'Completed',
    userType: 'New',
  },
]

const Coupon = () => {
  return (
    <Column gap="lg">
      <Column background="white" padding="2x" width="100%" radius="md" gap="2x">
        <Typography fontColor="grey500" fontSize="2x" fontWeight="xlg">
          Create Promo
        </Typography>
        <PromotionForm />
      </Column>
      <Typography fontColor="grey500" fontSize="2x" fontWeight="xlg">
        Coupon List
      </Typography>
      <DataTable
        data={staticData}
        search
        columns={columns}
        onFilterChange={(filter) => console.log('Filter changed:', filter)}
        breadcrum={<Breadcrum title="" buttons={buttons} />}
      />
    </Column>
  )
}

export default Coupon
