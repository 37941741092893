import DocumentSetup from '../pages/document-setup'
import Dashboard from '../pages/dashboard'
import {
  BANNER_SETUP,
  BUSINESS_SETUP,
  COUPON,
  CUSTOMER_MANAGEMENT,
  CUSTOMER_MANAGEMENT_VIEW,
  DRIVER_MANAGEMENT_VIEW,
  TRIP_MANAGEMENT_VIEW,
  DASHBOARD,
  DOCUMENT_SETUP,
  DRIVER_MANAGEMENT,
  PAGE_SETUP,
  PARCEL_FARE_SETUP,
  PARCEL_SETUP,
  PUSH_NOTIFICATIONS,
  REPORT,
  SETTINGS,
  TOPUP_LIST,
  TRANSACTION_REQUEST,
  TRIP_FARE_SETUP,
  TRIP_MANAGEMENT,
  VEHICE_LIST,
  VEHICLE_SETUP,
  WITHDRAW_REQUEST,
  ZONE_SETUP,
  DEFAULT_PARCEL_PRICE,
  DEFAULT_RIDE_PRICE,
} from '@constants/routeConstant'
import {
  BannerSetup,
  BusinessSetup,
  Coupon,
  CustomerManagement,
  DriverManagement,
  PageSetup,
  ParcelFareSetup,
  ParcelSetup,
  PushNotifications,
  Report,
  Settings,
  TopupList,
  TransactionRequest,
  TripFareSetup,
  TripManagement,
  VehicleList,
  VehicleSetup,
  CustomerManagementView,
  DriverManagementView,
  TripManagementView,
  WithdrawRequest,
  ZoneSetup,
} from '@pages/index'
import DefaultPrice from '@pages/fare-management/trip-fare-setup/default-price'
import DefaultParcelPrie from '@pages/fare-management/parcel-fare-setup/default-price'

const privateRoutes = [
  {
    Component: Dashboard,
    exact: true,
    path: DASHBOARD,
  },
  {
    Component: DocumentSetup,
    exact: true,
    path: DOCUMENT_SETUP,
  },
  {
    Component: CustomerManagement,
    exact: true,
    path: CUSTOMER_MANAGEMENT,
  },
  {
    Component: CustomerManagementView,
    exact: true,
    path: CUSTOMER_MANAGEMENT_VIEW,
  },
  {
    Component: DriverManagement,
    exact: true,
    path: DRIVER_MANAGEMENT,
  },
  {
    Component: DriverManagementView,
    exact: true,
    path: DRIVER_MANAGEMENT_VIEW,
  },
  {
    Component: ZoneSetup,
    exact: true,
    path: ZONE_SETUP,
  },
  {
    Component: TripManagement,
    exact: true,
    path: TRIP_MANAGEMENT,
  },

  {
    Component: TripManagementView,
    exact: true,
    path: TRIP_MANAGEMENT_VIEW,
  },
  {
    Component: VehicleList,
    exact: true,
    path: VEHICE_LIST,
  },
  {
    Component: VehicleSetup,
    exact: true,
    path: VEHICLE_SETUP,
  },
  {
    Component: ParcelFareSetup,
    exact: true,
    path: PARCEL_FARE_SETUP,
  },
  {
    Component: TripFareSetup,
    exact: true,
    path: TRIP_FARE_SETUP,
  },

  {
    Component: ParcelSetup,
    exact: true,
    path: PARCEL_SETUP,
  },
  {
    Component: BannerSetup,
    exact: true,
    path: BANNER_SETUP,
  },
  {
    Component: Coupon,
    exact: true,
    path: COUPON,
  },
  {
    Component: PushNotifications,
    exact: true,
    path: PUSH_NOTIFICATIONS,
  },
  {
    Component: TopupList,
    exact: true,
    path: TOPUP_LIST,
  },
  {
    Component: WithdrawRequest,
    exact: true,
    path: WITHDRAW_REQUEST,
  },
  {
    Component: TransactionRequest,
    exact: true,
    path: TRANSACTION_REQUEST,
  },
  {
    Component: Report,
    exact: true,
    path: REPORT,
  },
  {
    Component: PageSetup,
    exact: true,
    path: PAGE_SETUP,
  },
  {
    Component: BusinessSetup,
    exact: true,
    path: BUSINESS_SETUP,
  },
  {
    Component: Settings,
    exact: true,
    path: SETTINGS,
  },
  {
    Component: DefaultPrice,
    exact: true,
    path: DEFAULT_RIDE_PRICE,
  },
  {
    Component: DefaultParcelPrie,
    exact: true,
    path: DEFAULT_PARCEL_PRICE,
  },
]

export default privateRoutes
