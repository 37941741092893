import styled from '@emotion/styled'
import { CSSProperties, ReactNode } from 'react'
import { theme } from '../utils'

interface ButtonProps {
  radius?: keyof typeof theme.spacing
  textColor?: keyof typeof theme.colors
  bgColor?: keyof typeof theme.colors
  padding?: keyof typeof theme.spacing
  paddingX?: keyof typeof theme.spacing
  paddingY?: keyof typeof theme.spacing
  border?: string
  fontSize?: keyof typeof theme.fontSizes
  style?: CSSProperties
  type?: 'button' | 'submit'
  outline?: 'true' | 'false'
  disabled?: boolean
  gap?: keyof typeof theme.spacing
  fontWeight?: keyof typeof theme.fontWeight
  width?: string
}

interface ButtonComponentProps extends ButtonProps {
  title: string
  bgColor?: keyof typeof theme.colors
  loading?: boolean
  onClick?: () => void
  frontIcon?: ReactNode
  backIcon?: ReactNode
  gap?: keyof typeof theme.spacing
  loadingColor?: keyof typeof theme.colors
}

const StyledButton = styled.button<ButtonProps>`
  outline: none;
  border: ${(props) =>
    props.borderColor
      ? `1px solid ${theme.colors[props.borderColor]}`
      : 'none'};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  border: ${({ border }) =>
    border
      ? theme.colors[border]
        ? `1px solid ${theme.colors[border]}`
        : border
      : 'none'};
  background-color: ${(props) =>
    theme.colors[props.bgColor as keyof typeof theme.colors] || props.bgColor};

  gap: ${(props) => theme.spacing[props.gap || 'sm']}px;
  font-size: ${(props) => theme.fontSizes[props.fontSize || 'md']}px;
  // font-size: ${(props) => theme.fontSizes[props.fontSize || 'md']}px;


  color: ${(props) =>
    theme.colors[props.textColor as keyof typeof theme.colors] ||
    props.textColor};

  width: ${(props) => props.width || '100%'};
  border-radius: ${({ radius }) =>
    radius ? `${theme.spacing[radius]}px` : '4px'};

  ${(props) => {
    const defaultPaddingY = '8'
    const defaultPaddingX = '12'

    if (props.padding) {
      return `padding: ${theme.spacing[props.padding]}px;`
    } else if (props.paddingX && props.paddingY) {
      return `
        padding: ${theme.spacing[props.paddingY]}px ${theme.spacing[props.paddingX]}px;
      `
    } else if (props.paddingX) {
      return `
        padding-left: ${theme.spacing[props.paddingX]}px;
        padding-right: ${theme.spacing[props.paddingX]}px;
        padding-top: ${defaultPaddingY}px;
        padding-bottom: ${defaultPaddingY}px;
      `
    } else if (props.paddingY) {
      return `
        padding-top: ${theme.spacing[props.paddingY]}px;
        padding-bottom: ${theme.spacing[props.paddingY]}px;
        padding-left: ${defaultPaddingX}px;
        padding-right: ${defaultPaddingX}px;
      `
    }
    return `padding: ${defaultPaddingY}px ${defaultPaddingX}px;`
  }};
`

const Button = ({
  onClick,
  title,
  type = 'button',
  loading,
  frontIcon,
  backIcon,
  disabled,

  gap,
  style,
  bgColor,
  fontSize,
  // loadingColor,
  ...restProps
}: ButtonComponentProps) => {
  return (
    <StyledButton
      onClick={onClick}
      disabled={loading || disabled}
      type={type}
      gap={gap}
      style={style}
      fontSize={fontSize} 
      bgColor={bgColor}
      {...restProps}
    >
      {!!loading ? (
        // <Spinner size={10} color={loadingColor || 'black'} />
        <p>Loading</p>
      ) : (
        <>
          {frontIcon}
          <span>{title}</span>
          {backIcon}
        </>
      )}
    </StyledButton>
  )
}

export default Button
