import { PaginationProps } from './interface'
import {
  PaginationList,
  PaginationOrderListWrapper,
  PaginationWrapper,
  Select,
} from './style'

const Pagination = ({ totalPage, currentPage }: PaginationProps) => {
  // const incrementPage = () => {
  //   return currentPage + 1
  // }

  // const decreasePage = () => {
  //   return currentPage - 1
  // }

  if (totalPage === 1) {
    return null // Don't render anything if totalPage is 1
  }
  return (
    <PaginationWrapper>
      <PaginationOrderListWrapper>
        {currentPage > 1 && (
          <PaginationList
            onClick={() => {
              // const page: number = decreasePage()
              // onClick && onClick(page)
            }}
          >
            {/* <FaAngleLeft size={18} /> */}
            <h1>ok</h1>
          </PaginationList>
        )}
        {currentPage > 5 && (
          <PaginationList border={true} color="primary">
            {currentPage}
          </PaginationList>
        )}
        {Array.from(
          { length: totalPage > 5 ? 5 : totalPage },
          (_, index) => index + 1,
        ).map((_, index) => {
          const page = index + 1
          return (
            <PaginationList
              border={currentPage === page}
              color={currentPage === page ? 'primary' : 'gray400'}
              key={index}
              onClick={() => {
                // onClick && onClick(page)
              }}
            >
              {index + 1}
            </PaginationList>
          )
        })}
        {totalPage > 5 && (
          <Select>
            <option>Select</option>
            {Array.from(
              { length: totalPage - 5 },
              (_, index) => index + 1,
            )?.map((_, index) => {
              const page = index + 6
              return (
                <option key={index} value={page}>
                  {page}
                </option>
              )
            })}
          </Select>
        )}
        {currentPage < totalPage && (
          <PaginationList
            onClick={() => {
              // const page: number = incrementPage()
              // onClick && onClick(page)
            }}
          >
            {/* <FaAngleRight size={18} /> */}
            <h2>hello</h2>
          </PaginationList>
        )}
      </PaginationOrderListWrapper>
    </PaginationWrapper>
  )
}

export default Pagination
