import Typography from '@components/Typography'
import Button from '../Button'
import { BreadCrumStyle } from './style'
import { BreadcrumProps, ButtonStyleProps } from './interface'
import { Row } from '@components/Grid'

const Breadcrum = ({
  title,
  buttons,
  justifyContent,
  padding,
  border,
  wrapperPadding,
  ...restProps
}: BreadcrumProps & {
  buttonStyle?: ButtonStyleProps
  justifyContent?: string
  wrapperPadding?: string
  padding?: string
  border?: string;
}) => {
  return (
    <BreadCrumStyle
      justifyContent={justifyContent}
      padding={padding}
      {...restProps}
    >
      {title && (
        <Typography
          variant="p"
          fontSize="md"
          fontWeight="xlg"
          style={{ padding: '10px 10px 10px 10px' }}
        >
          {title}
        </Typography>
      )}
      {!!buttons?.length && (
        <Row style={{ columnGap: 10 }}>
          {buttons?.map((button) => {
            const { title, type,frontIcon,border, wrapperPadding,BackIcon,padding, paddingX, paddingY, ...restProps } = button
            return (
              <div key={button?.title}
              style={{
                padding: wrapperPadding || '0', 
              }}
              >
                {type === 'button' ? (
                  <Button
                    title={title}
                    frontIcon={frontIcon}
                    backIcon={BackIcon}
                    {...restProps}
                    style={{ fontWeight: '500' }}
                    radius="sm"
                    border={button?.border}
                    padding={padding}
                    paddingX={paddingX} 
                    paddingY={paddingY} 
                    bgColor={button?.bgColor}
                    textColor={button?.textColor}
                  />
                ) : (
                  <>
                    test
                  </>
                )}
              </div>
            )
          })}
        </Row>
      )}
    </BreadCrumStyle>
  )
}

export default Breadcrum
