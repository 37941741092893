import Spinner from '@components/Spinner'
import Typography from '@components/Typography'
import { StyleTableBody, StyleTData, StyleTRow ,Test} from './style'
import Image from '@components/Image'
import { Logo } from '@assets/images'
import { DataTableProps } from './interface'

const TableBody = ({ data, columns, loading }: DataTableProps) => {

  console.log(data,"datatablebody")
  return (
    <StyleTableBody>
      {loading ? (
        <StyleTRow>
          <StyleTData colSpan={columns?.length + 1} style={{ textAlign: 'center', paddingTop: 100, paddingBottom: 100 }}>
            <Spinner />
          </StyleTData>
        </StyleTRow>
      ) : (
        <>
      
          {!!data?.length ? (
            data.map((item: any, index: number) => (
              <>
              <StyleTRow key={index}>
                {columns.map((column: any, i: number) => (
                  <StyleTData key={i} style={{ borderBottom: '1px solid #E9E9E9'}}>
                    {column.render ? column.render({ row: item, index: index + 1 }) : item[column.key]}
                  </StyleTData>
                ))}
              </StyleTRow>
              </>
            ))
          ) : (
            <StyleTRow>
              <StyleTData colSpan={columns?.length + 1} style={{ padding: '40px 0px', textAlign: 'center' }}>
              <Image src={Logo} alt="Not Found Image" />
                <Typography variant="p" fontSize="lg" fontColor="gray400">
                  No Data Found
                </Typography>
              </StyleTData>
            </StyleTRow>
          )}
        </>
      )}
    </StyleTableBody>
  );
  
}

export default TableBody
