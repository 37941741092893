const initialState = {
  user: {},
  categories: {
    items: [],
    totalPages: 0,
    page: 0,
    totalItems: 0,
  },
}

export default initialState
