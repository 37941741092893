import styled from '@emotion/styled'
import theme from '@utils/theme'
import { useCallback } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'

const RangeContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const SlidersControl = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`

const RangeInput = styled.input`
  -webkit-appearance: none;
  appearance: none;
  height: 6px;
  width: 100%;
  position: absolute;
  background-color: #c6c6c6;
  pointer-events: none;
  border-radius: 10px;
  transition: 200ms;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    pointer-events: all;
    width: 20px;
    height: 20px;
    background-color: ${theme.colors.primary};
    border-radius: 50%;
    box-shadow: 0 0 0 1px ${theme.colors.primary}80;
    cursor: pointer;
  }

  &::-moz-range-thumb {
    pointer-events: all;
    width: 20px;
    height: 20px;
    background-color: ${theme.colors.primary};
    border-radius: 50%;
    box-shadow: 0 0 0 1px #c6c6c6;
    cursor: pointer;
  }

  &::-webkit-slider-thumb:active {
    box-shadow:
      inset 0 0 3px #387bbe,
      0 0 9px #387bbe;
    -webkit-box-shadow:
      inset 0 0 3px #387bbe,
      0 0 9px #387bbe;
  }
`

const FromSlider = styled(RangeInput)<{ disable?: 0 | 1 }>`
  height: 0;
  z-index: 1;
  position: absolute;
  display: ${(props) => (props.disable === 1 ? 'flex' : 'none')};
  align-items: center;
`

const RangeInputField = ({
  minValue,
  maxValue,
  name,
  form,
  multiple = true,
}: {
  minValue: number
  maxValue: number
  name: string
  form: any
  multiple?: boolean
}) => {
  const [fromValue, setFormValue] = useState<number>(
    multiple ? form.values[name][0] : minValue,
  )
  const [toValue, setToValue] = useState<number>(
    multiple ? form.values[name][1] : form.values[name],
  )

  useEffect(() => {
    if (typeof document !== 'undefined') {
      const fromSlider = document.getElementById(
        `fromSlider${name}`,
      ) as HTMLInputElement
      const toSlider = document.getElementById(
        `toSlider${name}`,
      ) as HTMLInputElement

      const controlSlider = (
        isFromSlider: boolean,
        slider: HTMLInputElement,
        otherSlider: HTMLInputElement,
      ) => {
        const [parsedSlider, parsedOtherSlider] = getParsed(slider, otherSlider)
        fillSlider(
          fromSlider,
          toSlider,
          '#C6C6C6',
          theme.colors.primary,
          toSlider,
        )
        if (isFromSlider) {
          if (parsedSlider > parsedOtherSlider) {
            fromSlider.value = parsedOtherSlider.toString()
            setFormValue(parsedOtherSlider)
          } else {
            setFormValue(parsedSlider)
          }
        } else {
          if (parsedSlider < parsedOtherSlider) {
            fromSlider.value = parsedOtherSlider.toString()
            setToValue(parsedOtherSlider)
          } else {
            setToValue(parsedSlider)
          }
        }
      }

      const controlInput = (
        isFormInput: boolean,
        fromSlider: HTMLInputElement,
        fromInput: HTMLInputElement,
        toInput: HTMLInputElement,
      ) => {
        const [from, to] = getParsed(fromInput, toInput)
        if (isFormInput) {
          if (from > to) {
            fromSlider.value = to.toString()
            fromInput.value = to.toString()
            setFormValue(to)
          } else {
            fromSlider.value = from.toString()
          }
        } else {
          if (from <= to) {
            toSlider.value = to.toString()
            toInput.value = to.toString()
            setToValue(to)
          } else {
            toInput.value = from.toString()
            setToValue(from)
          }
        }
      }

      const getParsed = (
        currentFrom: HTMLInputElement,
        currentTo: HTMLInputElement,
      ): [number, number] => {
        const from = parseInt(currentFrom.value, 10)
        const to = parseInt(currentTo.value, 10)
        return [from, to]
      }

      const fillSlider = (
        from: HTMLInputElement,
        to: HTMLInputElement,
        sliderColor: string,
        rangeColor: string,
        controlSlider: HTMLInputElement,
      ) => {
        const rangeDistance = parseInt(to.max) - parseInt(to.min)
        const fromPosition = parseInt(from.value) - parseInt(to.min)
        const toPosition = parseInt(to.value) - parseInt(to.min)
        controlSlider.style.background = `linear-gradient(
        to right,
        ${sliderColor} 0%,
        ${sliderColor} ${(fromPosition / rangeDistance) * 100}%,
        ${rangeColor} ${(fromPosition / rangeDistance) * 100}%,
        ${rangeColor} ${(toPosition / rangeDistance) * 100}%,
        ${sliderColor} ${(toPosition / rangeDistance) * 100}%,
        ${sliderColor} 100%)`
      }

      const setToggleAccessible = (currentTarget: HTMLInputElement) => {
        const toSlider = document.getElementById(
          `toSlider${name}`,
        ) as HTMLInputElement
        if (Number(currentTarget.value) <= 0) {
          toSlider.style.zIndex = '2'
        } else {
          toSlider.style.zIndex = '0'
        }
      }

      fillSlider(
        fromSlider,
        toSlider,
        '#C6C6C6',
        theme.colors.primary,
        toSlider,
      )
      setToggleAccessible(toSlider)

      fromSlider.oninput = () => controlSlider(true, fromSlider, toSlider)
      toSlider.oninput = () => controlSlider(false, fromSlider, toSlider)
      controlInput(true, fromSlider, fromSlider, toSlider)
      controlInput(false, toSlider, fromSlider, toSlider)
    }
  }, [minValue, maxValue])

  const debouncedSetFieldValue = useCallback(
    _.debounce(
      (name: string, value: number | [number, number]) =>
        form.setFieldValue(name, value),
      10,
    ),
    [],
  )

  const onInputChange = (value: string, type: 'from' | 'to') => {
    if (multiple) {
      if (type === 'from') {
        setFormValue(Number(value))
        debouncedSetFieldValue(name, [Number(value), form.values[name][1]])
      } else {
        setToValue(Number(value))
        debouncedSetFieldValue(name, [form.values[name][0], Number(value)])
      }
    } else {
      setToValue(Number(value))
      debouncedSetFieldValue(name, Number(value))
    }
  }

  return (
    <RangeContainer>
      <SlidersControl>
        <FromSlider
          id={`fromSlider${name}`}
          type="range"
          defaultValue={minValue.toString()}
          min={minValue}
          max={maxValue}
          disable={multiple ? 1 : 0}
          disabled={!multiple}
          value={fromValue}
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            onInputChange(e.currentTarget.value, 'from')
          }
        />
        <RangeInput
          id={`toSlider${name}`}
          type="range"
          defaultValue={maxValue.toString()}
          min={minValue}
          max={maxValue}
          value={toValue}
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            onInputChange(e.currentTarget.value, 'to')
          }
        />
      </SlidersControl>
    </RangeContainer>
  )
}

export default RangeInputField
