import { Column, Row } from '@components/Grid'
import Image from '@components/Image'
import Typography from '@components/Typography'

interface ICustomerInforCard {
  title?: string
  customerImage: string
  customerName: string
  phoneNumber: string
  email: string
}
export const CustomerInfoCard = ({
  customerImage,
  customerName,
  phoneNumber,
  title = 'Customer Info',
  email,
}: ICustomerInforCard) => {
  return (
    <Column
      background="white"
      padding="lg"
      radius="sm"
      border="grey50"
      gap="2x"
      width="100%"
    >
      <Typography fontColor="primary" fontSize="lg">
        {title}
      </Typography>
      <Row align="center" gap="lg" width="100%">
        <Image
          src={customerImage}
          size={100}
          alt="customerPicture"
          borderRadiusFull
        />
        <Column gap="md">
          <Typography fontWeight="xlg">{customerName}</Typography>
          <Typography fontWeight="xlg">{phoneNumber}</Typography>
          <Typography fontWeight="xlg">{email}</Typography>
        </Column>
      </Row>
    </Column>
  )
}
