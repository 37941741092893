import { css } from '@emotion/react'
import { CSSProperties } from 'react'
import { theme } from '../utils'

type JustifyContent =
  | 'center'
  | 'left'
  | 'right'
  | 'flex-start'
  | 'flex-end'
  | 'space-between'
  | 'space-around'
  | 'space-evenly'

type AlignItems =
  | 'center'
  | 'left'
  | 'right'
  | 'flex-start'
  | 'flex-end'
  | 'space-between'
  | 'space-around'
  | 'stretch'
  | 'space-evenly'

interface IAlignment {
  justify?: JustifyContent
  align?: AlignItems
}

export interface IStyledBase extends IAlignment {
  width?: string
  background?: keyof typeof theme.colors
  style?: CSSProperties
  radius?: keyof typeof theme.spacing
  onClick?: () => void
  children?: React.ReactNode
  padding?: keyof typeof theme.spacing
  gap?: keyof typeof theme.spacing
  $marginTop?: keyof typeof theme.spacing
  border?: keyof typeof theme.colors
  height?: string
  flexGrow?: number
  flexShrink?: number
  flexBasis?: number
  overflow?: 'visible' | 'hidden' | 'scroll' | 'auto'
  flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse'
}

export const baseStyles = (props: IStyledBase) => css`
  background-color: ${props.background
    ? theme.colors[props.background]
    : 'transparent'};
  width: ${props.width || 'auto'};
  border-radius: ${theme.spacing[props.radius || 'zero']}px;
  padding: ${theme.spacing[props.padding || 'zero']}px;
  margin-top: ${theme.spacing[props.$marginTop || 'zero']}px;
  align-items: ${props.align || 'flex-start'};
  justify-content: ${props.justify || 'flex-start'};
  gap: ${theme.spacing[props.gap || 'zero']}px;
  font-size: ${theme.fontSizes.md}px;
  border: ${props.border
    ? `1px solid ${theme.colors[props.border]}`
    : '1px solid transparent'};
  height: ${props.height || ''};
  flex-grow: ${props.flexGrow || ''};
  flex-shrink: ${props.flexShrink || ''};
  flex-basis: ${props.flexBasis || ''};
  overflow: ${props.overflow || 'auto'};
  flex-wrap: ${props.flexWrap || 'nowrap'};
  word-wrap: break-word;
  overflow-wrap: break-word;
`
