import { CSSProperties, useState } from 'react'
import Accordion from '@components/Accordion'
import SvgIcon from '@components/SvgIcon'
import { Column, Row } from '@components/Grid'
import { Logo } from '@assets/images'
import AdminProfileCard from '@components/Card/AdminProfileCard'
import Image from '@components/Image'
import theme from '@utils/theme'
import Typography from '@components/Typography'
import { SidebarData } from '../../constants/sidebarData'
import styled from '@emotion/styled'
import { NavLink } from 'react-router-dom'
import { hamburger } from '@assets/svg'

export const Sidebar = ({ toggle }: { toggle: () => void }) => {
  const [expandedIndex, setExpandedIndex] = useState<number | null>(0)
  const [selectedIndex, setSelectedIndex] = useState<number>(0)
  const [activeSubIndex, setActiveSubIndex] = useState<number | null>(null)

  return (
    <Column background="white" width="100%" height="100vh" overflow="hidden">
      <SidebarContainer width="98%" gap="lg">
        <Column width="100%">
          <Row width="100%" justify="center" align="center">
            <HamburgerWrapper justify="space-between" align="left">
              <SvgIcon src={hamburger} onClick={toggle} />
            </HamburgerWrapper>
            <Image src={Logo} alt="logo" />
          </Row>
          <AdminProfileCard
            email="superadmin@gmail.com"
            adminRole="Superadmin"
            profilePicture="https://cdn.pixabay.com/photo/2023/08/11/08/29/highland-cattle-8183107_1280.jpg"
          />
        </Column>
        <Column width="100%">
          {SidebarData.map((data: any, index: number) => {
            const isExpanded = expandedIndex === index
            const isSelected = selectedIndex === index
            const headerStyle: CSSProperties = {
              border: 'none',
              marginBottom: 0,
              width: '100%',
              padding: '0px',
              background:
                isSelected || isExpanded
                  ? theme.colors.primary50
                  : 'transparent',
              color:
                isSelected || isExpanded
                  ? theme.colors.primary50
                  : theme.colors.textPrimary,
            }

            return (
              <Accordion
                key={data.id}
                expanded={isExpanded}
                setExpanded={() => {
                  setExpandedIndex(isExpanded ? null : index)
                  setSelectedIndex(index)
                }}
                icon={!!data.subItems}
                headerStyle={headerStyle}
                headerComponent={
                  <>
                    {!data.subItems ? (
                      <Link to={!data.subItems && data.navigate}>
                        <Row
                          align="center"
                          width="100%"
                          justify="space-between"
                          padding="sm"
                          // onClick={handleNavigate}
                        >
                          <Row align="center" gap="sm">
                            <SvgIcon
                              src={data.icon}
                              svgColor={isSelected ? 'primary' : ''}
                            />
                            <Typography
                              fontWeight="xlg"
                              fontSize="md"
                              fontColor={isSelected ? 'primary' : ''}
                              onClick={() => {
                                setSelectedIndex(index)
                                // e.stopPropagation()
                              }}
                            >
                              {data.title}
                            </Typography>
                          </Row>
                        </Row>
                      </Link>
                    ) : (
                      <Row
                        align="center"
                        width="100%"
                        justify="space-between"
                        padding="sm"
                        // onClick={handleNavigate}
                      >
                        <Row align="center" gap="sm">
                          <SvgIcon
                            src={data.icon}
                            svgColor={isSelected ? 'primary' : ''}
                          />
                          <Typography
                            fontWeight="xlg"
                            fontSize="md"
                            fontColor={isSelected ? 'primary' : ''}
                            onClick={() => {
                              setSelectedIndex(index)
                              // e.stopPropagation()
                            }}
                          >
                            {data.title}
                          </Typography>
                        </Row>
                      </Row>
                    )}
                  </>
                }
                childrenComponent={
                  <>
                    {data.subItems &&
                      data.subItems.map((sub: any, subIndex: number) => {
                        const isSubSelected = activeSubIndex === subIndex

                        return (
                          <Link
                            key={subIndex}
                            to={sub.navigate}
                            isselected={isSubSelected ? 1 : 0}
                          >
                            <SubItemsWrapper
                              key={subIndex}
                              padding="sm"
                              onClick={() => setActiveSubIndex(subIndex)}
                            >
                              <Typography
                                fontColor={
                                  isSubSelected ? 'primary' : 'textPrimary'
                                }
                              >
                                {sub.title}
                              </Typography>
                            </SubItemsWrapper>
                          </Link>
                        )
                      })}
                  </>
                }
              />
            )
          })}
        </Column>
      </SidebarContainer>
    </Column>
  )
}

const SubItemsWrapper = styled(Row)`
  padding: 8px;
  padding-left: 36px;
  cursor: pointer;
`
export const Link = styled(NavLink)<{ isselected?: 1 | 0 }>`
  text-decoration: none;
  color: ${(props) =>
    props.isselected ? theme.colors.primary : theme.colors.grey500};
`
const SidebarContainer = styled(Column)`
  margin: 0 auto;
`
const HamburgerWrapper = styled(Row)`
  position: absolute;
  left: 12px;
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`
