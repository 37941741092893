import React from 'react'
import { Column, Row } from './Grid'
import Typography from './Typography'
import SvgIcon from './SvgIcon'
import { money, motorbike, route, userGroupIcon } from '@assets/svg'
import theme from '@utils/theme'
import styled from '@emotion/styled'
import Image from './Image'

interface IDashCard {
  id: number
  icon?: string
  title: string
  value: string
  background?: keyof typeof theme.colors
  image?: string
}
interface DashboardProps {
  data: IDashCard[]
  row?: number
}
const Dashcard = ({ data, row = 4 }: DashboardProps) => {
  return (
    <DashboardWrapper width="100%" gap="sm" justify="space-between" row={row}>
      {data.map(({ id, icon, title, value, background, image }) => {
        return (
          <Column
            key={id}
            padding="lg"
            background="white"
            gap="lg"
            radius="md"
            border="grey50"
          >
            <Row justify="space-between" align="center" width="100%">
              <Row
                background={background ? background : ''}
                padding={icon ? 'lg' : 'zero'}
                radius="md"
              >
                {icon && <SvgIcon src={icon} />}
                {image && <Image src={image} alt={title} />}
              </Row>
              <Row>
                <Typography fontSize="1x" fontWeight="xlg">
                  {value}
                </Typography>
              </Row>
            </Row>
            <Typography fontColor="grey400" fontSize="md" lineHeight="1x">
              {title}
            </Typography>
          </Column>
        )
      })}
    </DashboardWrapper>
  )
}

export default Dashcard

export const dashcardData: IDashCard[] = [
  {
    id: 1,
    icon: userGroupIcon,
    title: 'Total Active Customers',
    value: '5',
    background: 'primary50',
  },
  {
    id: 2,
    icon: money,
    title: 'Total Earnings',
    value: 'Rs 2,345.48',
    background: 'green50',
  },
  {
    id: 3,
    icon: motorbike,
    title: 'Total Active Drivers',
    value: '15',
    background: 'lightCyan',
  },
  {
    id: 4,
    icon: route,
    title: 'Total Trips',
    value: '25',
    background: 'lightBlue',
  },
]

const DashboardWrapper = styled(Row)<{ row: number }>`
  display: grid;
  grid-template-columns: repeat(${({ row }) => row}, 1fr);
  @media (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 640px) {
    grid-template-columns: repeat(1, 1fr);
  }
`
