import { useState } from 'react'
import Button from '@components/Button'
import { Column, Row } from '@components/Grid'
import AddParcelCategory from '@components/Forms/Parcel/AddParcelCategory'
import AddWeightRange from '@components/Forms/Parcel/AddWeightRange'

const ParcelSetup = () => {
  const [activeButton, setActiveButton] = useState('Parcel Categories')

  const buttonConfigs = [
    { title: 'Parcel Categories', key: 'categories' },
    { title: 'Parcel weights', key: 'weights' },
  ]

  const renderedForm = () => {
    switch (activeButton) {
      case 'Parcel Categories':
        return <AddParcelCategory />
      case 'Parcel weights':
        return <AddWeightRange />

      default:
        return null
    }
  }
  return (
    <Column gap="sm" width="100%">
      <Row width="100%" gap="sm">
        {buttonConfigs.map((button) => (
          <Button
            key={button.key}
            title={button.title}
            bgColor={activeButton === button.title ? 'primary50' : 'white'}
            textColor={activeButton === button.title ? 'primary' : 'grey400'}
            radius="sm"
            width="fit-content"
            // borderColor={activeButton !== button.title && 'grey50'}
            onClick={() => setActiveButton(button.title)}
          />
        ))}
      </Row>
      <Row width="100%">{renderedForm()}</Row>
    </Column>
  )
}

export default ParcelSetup
