import { Column, Row } from '@components/Grid'
import Image from '@components/Image'
import Typography from '@components/Typography'

interface IAdminDetails {
  email: string
  adminRole: string
  profilePicture: string
}
const AdminProfileCard = ({
  email,
  adminRole,
  profilePicture,
}: IAdminDetails) => {
  return (
    <Row
      padding="sm"
      gap="sm"
      justify="space-between"
      background="primary50"
      align="center"
      width="100%"
      radius="sm"
    >
      <Row>
        <Image src={profilePicture} alt="image" size={42} borderRadiusFull />
      </Row>
      <Column gap="sm">
        <Typography fontSize="md">{email}</Typography>
        <Typography fontColor="grey300">{adminRole}</Typography>
      </Column>
    </Row>
  )
}

export default AdminProfileCard
