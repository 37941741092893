import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import theme from '@utils/theme'
import { HTMLAttributes } from 'react'

interface SpinnerProps extends Omit<HTMLAttributes<HTMLDivElement>, 'color'> {
  size?: number
  fontColor?: keyof typeof theme.colors
}
const LoadingIndicatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const LoadingIndicator = styled.div<SpinnerProps>`
  border-radius: 50%;
  width: ${({ size }) => (size ? size : 26)}px;
  height: ${({ size }) => (size ? size : 26)}px;
  border: 2px solid ${({ color }) => theme.colors[color || 'primary']};
  border-top-color: transparent;
  animation: ${spin} 1s linear infinite;
`

const Spinner = (props: SpinnerProps) => {
  return (
    <LoadingIndicatorWrapper>
      <LoadingIndicator {...props} />
    </LoadingIndicatorWrapper>
  )
}

export default Spinner
