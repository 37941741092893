export const LOGIN = `
  mutation ($input: AdminLoginInput!) {
    adminLogin(input: $input) {
      data {
        id
        name
        email
        phoneNo
        countryCode
        avatar
        token
        isActive
        deviceToken
        socketId
        createdAt
        updatedAt
      }
      message
    }
  }
`
