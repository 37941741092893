import { Auto, Car2, MotorBike2, Scooter } from '@assets/images'
import {
  motorbike,
  scooter,
  userBlock,
  userCheck,
  userGroupIcon,
  vehicle,
} from '@assets/svg'

export const driverAnalyticsData = [
  {
    id: 1,
    icon: userGroupIcon,
    title: 'Total Drivers',
    value: '5',
    background: 'red100',
  },
  {
    id: 2,
    icon: userCheck,
    title: 'Active Drivers',
    value: '3',
    background: 'green200',
  },
  {
    id: 3,
    icon: userBlock,
    title: 'Inactive Drivers',
    value: '0',
    background: 'lightCyan',
  },
  {
    id: 4,
    icon: vehicle,
    title: 'Car Drivers',
    value: '0',
    background: 'paleGreen',
  },
  {
    id: 5,
    icon: motorbike,
    title: 'Bike Drivers',
    value: '0',
    background: 'skyBlueMist',
  },
  {
    id: 6,
    icon: scooter,
    title: 'Scooter Drivers',
    value: '0',
    background: 'butterCream',
  },
]

export const vehicleData = [
  {
    id: 1,
    image: MotorBike2,
    title: 'Bike',
    value: '5',
  },
  {
    id: 2,
    image: Scooter,
    title: 'Scooter',
    value: '3',
  },
  {
    id: 3,
    image: Auto,
    title: 'Auto',
    value: '0',
  },
  {
    id: 4,
    image: Car2,
    title: 'Car',
    value: '7',
  },
]
