import { ChangeEvent, useState } from 'react'
import {
  StyleTableWrapper,
  StyleTable,
  TableHeaderWrapper,
  StyleLeftHeaderWrapper,
  StyleRightHeaderWrapper,
  StyleSearchWrapper,
  StyleSearchInput,
  StyleSearchIconWrapper,
} from './style'
import { DataTableProps } from './interface'
import { Column, Row } from '@components/Grid'
import TableHead from './TableHead'
import TableBody from './TableBody'
import Pagination from './Pagination'
import styled from '@emotion/styled'
// import Breadcrum from '@components/Breadcrum'
import SvgIcon from '@components/SvgIcon'
import { searchIcon, trashBin,fileDownload } from '@assets/svg'
import Button from '@components/Button'
import Breadcrum from '@components/Breadcrum'

const DataTable = ({
  data = [],
  columns,
  loading,
  pagination,
  search,
  leftComponent,
  rightComponent,
  onFilterChange,
  categoryData,
  onCategoryChange,
  onSearch,
  selectedRows,
  breadcrum,
  onSelectRow,
  onSelectAll,
  setIsMultipleDelete,
  setModalOpen,
}: DataTableProps) => {
  const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>(
    null,
  )
  const handleCategoryClick = (category: string, categoryId: string) => {
    onCategoryChange(category, categoryId)
    setSelectedCategoryId(categoryId)
  }

  return (
    <StyleTableWrapper>
    
    <StyleTable>
      {(!!search || !!leftComponent || !!rightComponent) && (
        <TableHeaderWrapper>
          {(!!search || !!leftComponent) && (
            <>
              <StyleLeftHeaderWrapper>
                {
                  <Icon
                    src={trashBin}
                    mode={selectedRows && selectedRows?.length > 0 ? 0 : 1}
                    onClick={() => {
                      setIsMultipleDelete && setIsMultipleDelete(true)
                      setModalOpen && setModalOpen(true)
                    }}
                  />
                }
                {!!search && (
                  <>
                    <StyleSearchWrapper>
                      <StyleSearchInput
                        placeholder="Search Here"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          onSearch && onSearch(e.target.value)
                        }}
                      />
                      <StyleSearchIconWrapper>
                        {/* <FaSearch size={16} color="#4b4b4b" /> */}
                        <SvgIcon src={searchIcon} size={16} />
                      </StyleSearchIconWrapper>
                    </StyleSearchWrapper>
                    {categoryData?.map((category: any) => (
                      <Button
                        key={category?._id}
                        title={category?.title}
                        textColor={
                          selectedCategoryId === category?._id
                            ? 'white'
                            : 'primary'
                        }
                        bgColor={
                          selectedCategoryId === category?._id
                            ? 'primary'
                            : 'transparent'
                        }
                        style={{
                          border: '1px solid #44238B',
                          borderRadius: 'xs',
                        }}
                        onClick={() =>
                          handleCategoryClick(category?.title, category?._id)
                        }  
                        
                      />
                    ))}
                  </>
                )}
                {!!leftComponent && leftComponent}
              </StyleLeftHeaderWrapper>
              {breadcrum && (
                <>
                  {breadcrum}
                </>
              )}
               {/* <Breadcrum
                title=""
                buttons={[
                  {
                    title: 'All',
                    type: 'button',
                    outline: 'true',
                    color: 'primary',
                    textColor: "primary",
                    wrapperPadding: '5px 0px 0px 0px',
                    // frontIcon: {SvgIcon},
                    // frontIcon: <SvgIcon />, 
                    // frontIcon: <SvgIcon src={trashBin} />,
                    bgColor: 'primary50', 
                    onClick: () => onFilterChange('pending'),
                  },
                  {
                    title: 'Pending',
                    type: 'button',
                    outline: 'true',
                    color: 'primary',
                    // bgColor: '#EBEBEB', 
                    textColor: "EBEBEB",
                    wrapperPadding: '5px 0px 0px 0px',
                    onClick: () => onFilterChange('approved'),
                  },
                  {
                    title: 'Ongoing',
                    type: 'button',
                    outline: 'true',
                    color: 'primary',
                    // bgColor: '#EBEBEB', 
                    textColor: "EBEBEB",
                    wrapperPadding: '5px 0px 0px 0px',
                    onClick: () => onFilterChange('approved'),
                  },
                  {
                    title: 'Accepted',
                    type: 'button',
                    outline: 'true',
                    color: 'primary',
                    // bgColor: '#EBEBEB', 
                    textColor: "EBEBEB",
                    wrapperPadding: '5px 0px 0px 0px',
                    onClick: () => onFilterChange('approved'),
                  },
                  {
                    title: 'Completed',
                    type: 'button',
                    outline: 'true',
                    // paddingX: 'md',
                    // paddingY: 'lg', 
                    color: 'primary',
                    wrapperPadding: '5px 0px 0px 0px',
                    // bgColor: 'primary50', 
                    // textColor: "primary",
                    onClick: () => onFilterChange('rejected'),
                  },
                  {
                    title: 'Download',
                    type: 'button',
                    outline: 'true',
                    border: 'primary',
                    color: 'primary',
                    textColor: "primary",
                    wrapperPadding: '0px 0px 0px 40px',
                    // paddingX: 'md', 
                    // paddingY: 'lg', 
                    // frontIcon: {SvgIcon},
                    // frontIcon: <SvgIcon />, 
                    frontIcon: <SvgIcon src={fileDownload} />,
                    bgColor: 'primary50', 
                    onClick: () => onFilterChange('pending'),
                  },
                ]}
              />    */}
            </>
          )}
          {!!rightComponent && (
            <StyleRightHeaderWrapper>{rightComponent}</StyleRightHeaderWrapper>
          )}
        </TableHeaderWrapper>
      )}
    </StyleTable>
    <StyleTable>
    <>
      <TableHead
          items={columns}
          // isAllSelected={(selectedRows ?? []).length === data?.length ?? 0}
          isAllSelected={false}
          onSelectAll={(isChecked) => onSelectAll && onSelectAll(isChecked)}
      />
      <TableBody
          data={data}
          columns={columns}
          loading={loading}
          selectedRows={selectedRows}
          onSelectRow={onSelectRow}
      />
    </>
    </StyleTable>
      {!!pagination && <Pagination {...pagination} />}
    </StyleTableWrapper>
  )
}

const Icon = styled(SvgIcon)<{ mode: 1 | 0 }>`
  font-size: 22px;
  opacity: ${(props) => (props.mode ? 0 : 1)};
  display: ${(props) => (props.mode ? 'none' : 'block')};
  transition:
    opacity 0.3s ease-in-out,
    visibility 0.3s ease-in-out;
  cursor: pointer;
`
export default DataTable
