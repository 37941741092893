import styled from '@emotion/styled'
import theme from '@utils/theme'

const StyledFormInput = styled.div`
  position: relative;
  width: calc(100% - 20px);

  input::placeholder {
    opacity: 0;
  }

  input {
    width: 100%;
    font-size: 12px;
    padding: 10px;
    outline: none;
    border: 1px solid ${theme.colors.gray100};
    border-radius: 5px;
  }

  label {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 12px;
    color: gray;
    transition: 0.3s;
  }

  input:focus + label,
  input:not(:placeholder-shown) + label {
    top: 0;
    font-size: 12px;
    background: #fff;
    padding: 7px;
  }
`

const StyledInput = () => {
  return (
    <StyledFormInput>
      <input type="email" placeholder="Email address" id="email" />
      <label htmlFor="email">Email Address</label>
    </StyledFormInput>
  )
}

export default StyledInput
