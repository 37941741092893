import React, { useState } from 'react'
import { Row } from '@components/Grid'
import styled from '@emotion/styled'
import theme from '@utils/theme'
import Image from '@components/Image'
import Typography from '@components/Typography'

interface IRadioButton {
  labelArray: any
  size: keyof typeof theme.fontSizes
  name: string
  onChange: (value: string) => void
  defaultValue?: string
}

const RadioButton = ({
  labelArray,
  size = '1x',
  name,
  onChange,
  defaultValue = '',
}: IRadioButton) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue)

  const handleChange = (value: string) => {
    setSelectedValue(value)
    onChange(value)
  }

  return (
    <Row gap="2x" width="max-content">
      {labelArray.map((option, index) => (
        <RadioLabel key={index} size={size}>
          <RadioInput
            type="radio"
            name={name}
            value={option.value}
            checked={selectedValue === option.value}
            onChange={() => handleChange(option.value)}
          />
          <LabelContent gap="xs" align="center" width="fit-content">
            {option.image && <Image src={option.image} alt="radioImages" />}
            <Typography>{option.title}</Typography>
          </LabelContent>
        </RadioLabel>
      ))}
    </Row>
  )
}

export default RadioButton

interface StyledProps {
  size: keyof typeof theme.fontSizes
}

const RadioLabel = styled.label<StyledProps>`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.sm}px;
  cursor: pointer;
`

const LabelContent = styled(Row)`
  white-space: nowrap; /* Prevent label from wrapping */
  flex-shrink: 0; /* Prevent layout from shrinking */
`

const RadioInput = styled.input`
  accent-color: ${theme.colors.primary};
  cursor: pointer;
`
