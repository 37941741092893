import Button from '@components/Button'
import FormField from '@components/FormField'
import { Column, Row } from '@components/Grid'
import Typography from '@components/Typography'
import { Form, Formik } from 'formik'
import React, { useState } from 'react'

const PromotionForm = () => {
  const buttonTitles = ['Bike', 'Car', 'Auto', 'Parcel']
  const [activeButton, setActiveButton] = useState(buttonTitles[0])
  const ChooseOption = [
    {
      title: 'New',
      value: 'new',
    },
    {
      title: 'Existing',
      value: 'existing',
    },
  ]
  return (
    <Formik initialValues={{}} onSubmit={() => {}}>
      <Form style={{ width: '100%' }}>
        <Column width="100%" gap="md">
          <Row width="100%" justify="space-between" align="center">
            <Row width="20%">
              <Typography>Service Category</Typography>
            </Row>
            <Row width="70%" gap="md" justify="space-between">
              <Row width="60%" gap="md">
                {buttonTitles.map((button, index) => (
                  <Button
                    key={index}
                    title={button}
                    bgColor={
                      activeButton === button ? 'primary50' : 'ghostWhite'
                    }
                    radius="sm"
                    textColor="primary"
                    onClick={() => setActiveButton(button)}
                  />
                ))}
              </Row>
              <Row width="30%" align="center" justify="space-between">
                <Row width="40%">
                  <Typography>Status</Typography>
                </Row>
                <Row>
                  <FormField type="switch" name="status" />
                </Row>
              </Row>
            </Row>
          </Row>
          <Row width="100%" justify="space-between" align="center">
            <Row width="20%">
              <Typography>Coupon Title</Typography>
            </Row>
            <Row width="70%">
              <FormField
                type="text"
                name="couponTitle"
                placeholder="Enter coupon title"
              />
            </Row>
          </Row>
          <Row width="100%" justify="space-between" align="center">
            <Row width="20%">
              <Typography>Coupon Code</Typography>
            </Row>
            <Row width="70%" align="center" gap="md">
              <Row width="40%">
                <FormField
                  type="text"
                  name="couponnCode"
                  placeholder="Enter coupon code"
                />
              </Row>
              <Row width="25%">
                <Typography>User Type</Typography>
              </Row>
              <Row width="40%">
                <FormField
                  type="radio"
                  name="vehicleType"
                  radioProps={{
                    labelArray: ChooseOption.map(({ title, value }) => ({
                      title,
                      value,
                    })),

                    size: '1x',
                  }}
                />
              </Row>
            </Row>
          </Row>
          <Row width="100%" justify="space-between" align="center">
            <Row width="20%">
              <Typography>Discount Type</Typography>
            </Row>
            <Row width="70%" align="center" gap="md">
              <Row width="40%">
                <FormField
                  type="select"
                  name="discontType"
                  placeholder="Select discount type"
                  select={{
                    titleAsKey: 'label',
                    valueAsKey: 'value',
                  }}
                  data={[
                    { value: 'data1', label: 'Option 1' },
                    { value: 'data2', label: 'Option 2' },
                    { value: 'data3', label: 'Option 3' },
                  ]}
                />
              </Row>
              <Row width="25%">
                <Typography>Discount amount</Typography>
              </Row>
              <Row width="40%">
                <FormField
                  type="select"
                  name="discountAmount"
                  placeholder="Select discount amount"
                  select={{
                    titleAsKey: 'label',
                    valueAsKey: 'value',
                  }}
                  data={[
                    { value: 'data1', label: 'Option 1' },
                    { value: 'data2', label: 'Option 2' },
                    { value: 'data3', label: 'Option 3' },
                  ]}
                />
              </Row>
            </Row>
          </Row>
          <Row width="100%" justify="space-between" align="center">
            <Row width="20%">
              <Typography>Zones</Typography>
            </Row>
            <Row width="70%" align="center" gap="md">
              <Row width="40%">
                <FormField
                  type="select"
                  name="zone"
                  placeholder="Select zone "
                  select={{
                    titleAsKey: 'label',
                    valueAsKey: 'value',
                  }}
                  data={[
                    { value: 'data1', label: 'Zone 1' },
                    { value: 'data2', label: 'Zone 2' },
                    { value: 'data3', label: 'Zone 3' },
                  ]}
                />
              </Row>
              <Row width="25%">
                <Typography>No.of Users </Typography>
              </Row>
              <Row width="40%">
                <FormField
                  type="select"
                  name="discontType"
                  placeholder="Select users number"
                  select={{
                    titleAsKey: 'label',
                    valueAsKey: 'value',
                  }}
                  data={[
                    { value: 'data1', label: '1' },
                    { value: 'data2', label: '2' },
                    { value: 'data3', label: '3' },
                  ]}
                />
              </Row>
            </Row>
          </Row>
          <Row width="100%" justify="space-between" align="center">
            <Row width="20%">
              <Typography>Start Date</Typography>
            </Row>
            <Row width="70%" align="center" gap="md">
              <Row width="40%">
                <FormField type="date" name="discountType" />
              </Row>
              <Row width="25%">
                <Typography>Expiry Date </Typography>
              </Row>
              <Row width="40%">
                <FormField type="date" name="noOfUsers" />
              </Row>
            </Row>
          </Row>
          <Row width="100%" justify="right">
            <Button
              title="Save"
              bgColor="primary"
              textColor="white"
              width="15%"
            />
          </Row>
        </Column>
      </Form>
    </Formik>
  )
}

export default PromotionForm
