import { hamburger, notification } from '@assets/svg'
import { Row } from '@components/Grid'
import Image from '@components/Image'
import Modal from '@components/Modal'
import Popover from '@components/Popover'
import SvgIcon from '@components/SvgIcon'
import styled from '@emotion/styled'
import { useState } from 'react'

const Header = ({ toggleSidebar, showHamburger }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  return (
    <Row
      background="white"
      width="100%"
      padding="lg"
      align="center"
      justify="space-between"
      gap="md"
    >
      {showHamburger && (
        <HamburgerWrapper>
          <SvgIcon src={hamburger} size={24} onClick={toggleSidebar} />
        </HamburgerWrapper>
      )}

      <Row gap="md" style={{ marginLeft: 'auto' }}>
        <SvgIcon
          src={notification}
          size={24}
          onClick={() => setIsModalOpen(true)}
        />
        <Popover content={<p>This Content Will be render in Popover.</p>}>
          <Image
            src="https://images.pexels.com/photos/2787341/pexels-photo-2787341.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
            alt="logo"
            borderRadiusFull
            size={24}
          />
        </Popover>
        <Modal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title="Notification Modal"
        >
          <p>This is the notification modal!</p>
        </Modal>
      </Row>
    </Row>
  )
}

export default Header

const HamburgerWrapper = styled(Row)`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`
