import {
  dashboard,
  document,
  maps,
  motorbike,
  vehicle,
  money,
  parcel,
  badge,
  notification,
  wallet,
  moneyExchange,
  briefcase,
  page,
  setting,
  userMultiple03,
} from '@assets/svg'
import {
  BANNER_SETUP,
  BUSINESS_SETUP,
  COUPON,
  CUSTOMER_MANAGEMENT,
  DASHBOARD,
  DOCUMENT_SETUP,
  DRIVER_MANAGEMENT,
  PAGE_SETUP,
  PARCEL_FARE_SETUP,
  PARCEL_SETUP,
  PUSH_NOTIFICATIONS,
  REPORT,
  SETTINGS,
  TOPUP_LIST,
  TRANSACTION_AND_REPORTS,
  TRIP_FARE_SETUP,
  TRIP_MANAGEMENT,
  VEHICE_LIST,
  VEHICLE_SETUP,
  WITHDRAW_REQUEST,
  ZONE_SETUP,
} from './routeConstant'

interface ISidebarData {
  id: number
  title: string
  navigate?: string
  icon?: string
  subItems?: {
    title: string
    navigate?: string
  }[]
}

export const SidebarData: ISidebarData[] = [
  {
    id: 1,
    title: 'Dashboard',
    navigate: DASHBOARD,
    icon: dashboard,
  },
  {
    id: 2,
    title: 'User Management',
    icon: userMultiple03,
    subItems: [
      {
        title: 'Customer',
        navigate: CUSTOMER_MANAGEMENT,
      },
      {
        title: 'Driver',
        navigate: DRIVER_MANAGEMENT,
      },
    ],
  },
  {
    id: 3,
    title: 'Document Setup',
    navigate: DOCUMENT_SETUP,
    icon: document,
  },
  {
    id: 4,
    title: 'Zone Setup',
    navigate: ZONE_SETUP,
    icon: maps,
  },
  {
    id: 5,
    title: 'Trip Management',
    navigate: TRIP_MANAGEMENT,
    icon: motorbike,
  },
  {
    id: 6,
    title: 'Vehicle Management',
    icon: vehicle,
    subItems: [
      {
        title: 'Vehicle Setup',
        navigate: VEHICLE_SETUP,
      },
      {
        title: 'Vehicle List',
        navigate: VEHICE_LIST,
      },
    ],
  },
  {
    id: 7,
    title: 'Fare Management',
    icon: money,
    subItems: [
      {
        title: 'Trip fare setup ',
        navigate: TRIP_FARE_SETUP,
      },
      {
        title: 'Parce fare Setup',
        navigate: PARCEL_FARE_SETUP,
      },
    ],
  },
  {
    id: 8,
    title: 'Parcel Setup',
    navigate: PARCEL_SETUP,
    icon: parcel,
  },
  {
    id: 9,
    title: 'Promotion',
    icon: badge,
    subItems: [
      {
        title: 'Banner setup',
        navigate: BANNER_SETUP,
      },
      {
        title: 'Coupon',
        navigate: COUPON,
      },
    ],
  },
  {
    id: 10,
    title: 'Push Notifications',
    navigate: PUSH_NOTIFICATIONS,
    icon: notification,
  },
  {
    id: 11,
    title: 'Wallet Management',
    icon: wallet,
    subItems: [
      {
        title: 'Topup List',
        navigate: TOPUP_LIST,
      },
      {
        title: 'Withdraw Request',
        navigate: WITHDRAW_REQUEST,
      },
    ],
  },
  {
    id: 12,
    title: 'Transaction & Reports',
    icon: moneyExchange,
    subItems: [
      {
        title: 'Transcation Request',
        navigate: TRANSACTION_AND_REPORTS,
      },
      {
        title: 'Report',
        navigate: REPORT,
      },
    ],
  },
  {
    id: 13,
    title: 'Page Setup',
    icon: page,
    navigate: PAGE_SETUP,
  },
  {
    id: 14,
    title: 'Business Setup',
    navigate: BUSINESS_SETUP,
    icon: briefcase,
  },
  {
    id: 15,
    title: 'Settings',
    navigate: SETTINGS,
    icon: setting,
  },
]
