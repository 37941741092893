import React, { useState, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import styled from '@emotion/styled'
import { Row, Column } from '@components/Grid'
import { Sidebar } from './SideBar'
import Header from './Header'
import theme from '@utils/theme'

const PrivateLayout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth > 768)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
      setIsSidebarOpen(window.innerWidth > 768)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen)
  }

  return (
    <Row>
      <ScrollbarWrapper
        isOpen={isSidebarOpen ? 1 : 0}
        height="100vh"
        border="grey50"
        width="260px"
      >
        <Sidebar toggle={toggleSidebar} />
      </ScrollbarWrapper>

      <MainContentColumn isSidebarOpen={isSidebarOpen ? 1 : 0}>
        <HeaderWrapper flexShrink={0} width="100%">
          <Header
            toggleSidebar={toggleSidebar}
            showHamburger={windowWidth <= 768}
          />
        </HeaderWrapper>

        <ContentArea>
          <Outlet />
        </ContentArea>
      </MainContentColumn>
    </Row>
  )
}

export default PrivateLayout

const ScrollbarWrapper = styled(Row)<{ isOpen?: 1 | 0 }>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 260px;
  overflow-y: auto;
  scrollbar-width: 4px;
  scrollbar-color: ${theme.colors.primary50} transparent;
  background-color: white;
  z-index: 2;
  transition: transform 0.3s ease-in-out;
  transform: ${(props) =>
    props.isOpen ? 'translateX(0)' : 'translateX(-100%)'};

  @media (max-width: 768px) {
    position: fixed;
  }
`

const MainContentColumn = styled(Column)<{ isSidebarOpen?: 1 | 0 }>`
  margin-left: ${(props) => (props.isSidebarOpen ? '260px' : '0')};
  flex-grow: 1;
  height: 100vh;
  overflow: hidden;
  transition: margin-left 0.3s ease-in-out;

  @media (max-width: 768px) {
    margin-left: 0;
  }
`

const ContentArea = styled(Row)`
  width: 100%;
  padding: 8px;
  flex-grow: 1;
  overflow-y: auto;
`

const HeaderWrapper = styled(Row)`
  min-height: 56px;
`
