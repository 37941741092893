import styled from '@emotion/styled'
import { useEffect, useRef, useState, ReactNode } from 'react'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  cursor: pointer;
`

const PopoverContent = styled.div<{ show: 0 | 1 }>`
  min-width: fit-content;
  height: fit-content;
  position: absolute;
  z-index: 50;
  transition: all 0.3s ease;
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: fixed;
  top: 6%;
  right: 1%;
`

const Content = styled.div`
  background-color: white;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 10px 30px 150px rgba(46, 38, 92, 0.25);
  width: 150px;
`

interface PopoverProps {
  children: ReactNode // Allow any valid React node
  content?: ReactNode // Content of the popover, also a React node
}

const Popover: React.FC<PopoverProps> = ({ children, content = 'click' }) => {
  const [show, setShow] = useState<boolean>(false)
  const wrapperRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setShow(false)
      }
    }

    if (show) {
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }
  }, [show])

  const handleToggle = () => {
    setShow((prevShow) => !prevShow)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault() // Prevent scrolling when space is pressed
      handleToggle()
    }
  }

  return (
    <Wrapper ref={wrapperRef}>
      <button
        onClick={handleToggle}
        onKeyDown={handleKeyDown}
        aria-expanded={show}
        aria-haspopup="true"
        style={{
          background: 'none',
          border: 'none',
          padding: 0,
          cursor: 'pointer',
        }}
      >
        {children}
      </button>
      <PopoverContent show={show ? 1 : 0}>
        <Content>{content}</Content>
      </PopoverContent>
    </Wrapper>
  )
}

export default Popover
